import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Socialusers } from '../models/socialusers';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServices {
  public socialusers = new Socialusers();
  public token;
  constructor(public httpClient: HttpClient) {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
   }
  makeServerSocialLoginCall(url, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.httpClient.post(url, data, httpOptions);
  }

}
