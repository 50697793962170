import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AdminService } from 'src/app/services/admin.service';
import { ConfirmDialogModel, ConfirmationsDialogComponent } from '../confirmations-dialog/confirmations-dialog.component';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.css']
})
export class QuestionDialogComponent implements OnInit {
  action: string;
  questionData: any;
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  categoryData;
  categories: string[] = [];
  showOption: boolean;
  // optionForYes: any[] = [ {option: ''} ];
  optionForYes: any[] = [];
  result;
  constructor(public dialogRef: MatDialogRef<QuestionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Question,
              public adminService: AdminService, public dialog: MatDialog) {
    // console.log(data);
    dialogRef.disableClose = true;
    this.questionData = { ...data};
    this.action = this.questionData.action;
   }
  //  option: string[] = this.category;
   ngOnInit() {
    this.getCategory();
  }
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
  addOptions() {
    // this.optionForYes.push({
    //   option: ''
    // });
    this.optionForYes.push('');
  }
  trackByFn(index: any, item: any) {
    return index;
 }

  removeOptions(i: number) {
    this.optionForYes.splice(i, 1);
  }

  addOptionsEdit() {
    this.questionData.sub_questions.push({
      sub_question_name: ''
    });
  }
  async removeEditOptions(id, i: number) {
    if (id === undefined) {
      this.questionData.sub_questions.splice(i, 1);
    } else {
      const message = `Are you sure, You want to delete this?`;
      const dialogData = new ConfirmDialogModel('Confirm Action', message);
      const dialogRef = this.dialog.open(ConfirmationsDialogComponent, {
        width: '400px',
        data: dialogData
      });
      this.result = await dialogRef.afterClosed().toPromise();
      if (this.result) {
        this.adminService.removeSubOptions(id).subscribe(
          (response: any) => {
            if (response.message === 'Sub Question Deleted Successfully') {
              this.questionData.sub_questions.splice(i, 1);
            }
            if (response.message === 'Sub Question Already Associates with Assigned Question') {
              const info = {
                msg: 'You can\'t delete this Sub Question. Because this Sub Question is already Associates with Assigned Question.',
                type: 'Alert',
              };
              this.alertDialog(info);
            }
          },
          (error) => {}
        );
      }

    }

  }

    getCategory() {
    this.adminService.getCategoriesList().subscribe(
      (data: any) => {
        // this.categoryData = data.categories;
        // tslint:disable-next-line:arrow-return-shorthand
        this.categories = data.categories.map((x) => { return x.category_name; });
        this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      },
      (error) => {

      }
    );
  }

   doAction() {
    this.dialogRef.close({ event: this.action, data:  { ques: this.questionData , options: this.optionForYes } });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (value === '') {
      return this.categories;
    } else {
      return this.categories.filter(option => option.toLowerCase().includes(filterValue));
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

export interface Question {
  id: number;
  question_id: number;
  category_name: string;
  question_name: string;
}
