import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { DialogComponent } from '../../component/dialog/dialog.component';
import { ConfirmDialogModel, ConfirmationsDialogComponent } from '../../component/confirmations-dialog/confirmations-dialog.component';
import { MngDataDialogComponent } from '../../component/mng-data-dialog/mng-data-dialog.component';
const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-question-add',
  templateUrl: './question-add.component.html',
  styleUrls: ['./question-add.component.css']
})
export class QuestionAddComponent implements OnInit {
  displayedColumns: string[] = ['select', 'SL.No.', 'id', 'question_name', 'category_name', 'Weightage'];
  displayManagers: string[] = ['sl' , 'manager' , 'action'];
  managers;
  managerData = [];
  selection = new SelectionModel<ProjectData>(true, []);
  projectId = 2;
  mangerId = 3;
  projectData;
  // questions: any[];
  questionData: any[];
  questions;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true  }) sort: MatSort;
  originalQuestions: any[];
  questionResponse: ProjectData = {} as ProjectData;
  projectQuestions;
  reporties;
  showTable: boolean = false;
  oldNoOfQs: number;
  result;
  fromDate;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  minDate = new Date();
  // minDate;
  currentSeverity: SeverityModel;
  questionsPerSeverity: QuestionPerSeverity[] = [] as QuestionPerSeverity[];
  assignedq;
  searchCtrl = '';
  severities: SeverityModel[] = [
    {
      severityRating: 3,
      severityRatingLevelWiseCAP: 20.00,
      weightageRange: 33
    },
    {
      severityRating: 2,
      severityRatingLevelWiseCAP: 50.00,
      weightageRange: 50
    },
    {
      severityRating: 1,
      severityRatingLevelWiseCAP: 30.00,
      weightageRange: 17
    }
  ];

  constructor(public router: Router, public dialog: MatDialog, public activatedRoute: ActivatedRoute,
              public adminService: AdminService, private spinner: NgxSpinnerService
    // public data: ProjectData
  ) {
    //  this.projectQuestions = {...data}
  }
  ngOnInit() {
    this.getQuestionsData();
    this.getProject();
  }
  onchangeEvent(e) {
    this.fromDate = e.target.value;
  }

  applyQFilter(filterValue: string) {
    this.questions.filter = filterValue.trim().toLowerCase();

    if (this.questions.paginator) {
      this.questions.paginator.firstPage();
    }
  }

  updateSeverities() {
    const n = this.questionResponse.numberOfQuestions;
    if (n) {
      const firstSeverity = this.severities.find(x => x.severityRating === 3);
      firstSeverity.questionDsbnAsPerSeverityCAP = Math.round(n * (firstSeverity.severityRatingLevelWiseCAP / 100));

      const secondSeverity = this.severities.find(x => x.severityRating === 1);
      secondSeverity.questionDsbnAsPerSeverityCAP = Math.round(n * (secondSeverity.severityRatingLevelWiseCAP / 100));

      const thirdSeverity = this.severities.find(x => x.severityRating === 2);
      // tslint:disable-next-line:max-line-length
      thirdSeverity.questionDsbnAsPerSeverityCAP = n - (firstSeverity.questionDsbnAsPerSeverityCAP + secondSeverity.questionDsbnAsPerSeverityCAP);

      this.severities.forEach(severity => {
        severity.weightagePerQuestion = Math.round((severity.weightageRange / severity.questionDsbnAsPerSeverityCAP) * 100) / 100;
      });
      // console.log(JSON.stringify(this.severities));
    }
  }

  disableCheckBox(row) {
    const currentQnPerSeverity = this.questionsPerSeverity.find(x => x.severityRating === this.currentSeverity.severityRating);
    if (currentQnPerSeverity && currentQnPerSeverity.questions.length >= this.currentSeverity.questionDsbnAsPerSeverityCAP) {
      if (currentQnPerSeverity.questions.indexOf(row) === -1) {
        return true;
      }
      return false;
    }
  }

  toggleCheckBox(row, event) {
    const currentQnPerSeverity = this.questionsPerSeverity.find(x => x.severityRating === this.currentSeverity.severityRating);
    if (currentQnPerSeverity && currentQnPerSeverity.questions.length >= this.currentSeverity.questionDsbnAsPerSeverityCAP) {
      if (event.checked) {
        row.isSelected = false;
        event.checked = false;
        row.weightage = null;
        // alert('you have exeeded questions for this severity');
        const info = {
          msg : 'you have exeeded questions for this severity',
          type : 'Alert',
          };
        this.alertDialog(info);
        return;
      } else {
        const checkQuestion = currentQnPerSeverity.questions.find(x => x.question_id === row.question_id);
        const checkQuestionIndex = currentQnPerSeverity.questions.indexOf(checkQuestion);
        if (checkQuestionIndex !== -1) {
          event.checked = false;
          row.weightage = null;
          currentQnPerSeverity.questions.splice(checkQuestionIndex, 1);
        }
      }
    } else {
      let currentQuestions = [];
      if (!currentQnPerSeverity && event.checked) {
        row.weightage = this.currentSeverity.weightagePerQuestion;
        currentQuestions.push(row);
        const questionPerSeverity: QuestionPerSeverity = {
          severityRating: this.currentSeverity.severityRating,
          questions: currentQuestions
        };
        this.questionsPerSeverity.push(questionPerSeverity);
      } else {
        currentQuestions = currentQnPerSeverity && currentQnPerSeverity.questions ? currentQnPerSeverity.questions : [];
        const checkQuestion = currentQuestions.find(x => x.question_id === row.question_id);
        const checkQuestionIndex = currentQuestions.indexOf(checkQuestion);
        if (checkQuestionIndex === -1 && event.checked) {
          row.weightage = this.currentSeverity.weightagePerQuestion;
          currentQuestions.push(row);
        } else {
          if (checkQuestionIndex !== -1 && !event.checked) {
            currentQuestions.splice(checkQuestionIndex, 1);
            row.weightage = null;
          }
        }
      }
    }
    // console.log(this.questionsPerSeverity);
  }
  alertDialog(obj) {
     this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
  confirmDialog() {

    const message = `your current changes will be lost`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationsDialogComponent, {
      width: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
            this.result = dialogResult;
            // console.log(this.result);
          });
  }
  async toggleTable() {
    if (this.questionResponse.numberOfQuestions) {
      if (this.questionResponse.numberOfQuestions < 3 || this.questionResponse.numberOfQuestions > this.questionData.length) {
        // alert('Please enter value between 3 and ' + this.questions.length);
        const info = {
        msg : 'Please enter value between 3 and ' + this.questionData.length,
        // tslint:disable-next-line:max-line-length
        // msg: 'You have exceeded max number,There are to total ' + this.questionData.length +  ' questions in your question bank.You have to enter minimum 3 value and maximum ' + this.questionData.length + ' value. If you want enter more than ' + this.questionData.length + ' questions. Please add more questions in your questions bank.',
        type : 'Alert',
        };
        this.alertDialog(info);
      } else {
        if (this.currentSeverity) {
          if (this.oldNoOfQs === this.questionResponse.numberOfQuestions) {
            return;
          }
          // const sure = confirm('your current changes will be lost');

          const message = `your current changes will be lost`;
          const dialogData = new ConfirmDialogModel('Confirm Action', message);
          const dialogRef = this.dialog.open(ConfirmationsDialogComponent, {
            width: '400px',
            data: dialogData
          });
          // dialogRef.afterClosed().subscribe(dialogResult => {
          //   this.result = dialogResult;
          //   // console.log(this.result);
          // });
          this.result = await dialogRef.afterClosed().toPromise();
          if (this.result) {
            this.questionsPerSeverity = [] as QuestionPerSeverity[];
            this.questions = this.originalQuestions;
            this.questions.forEach(qn => {
              qn.isSelected = false;
              qn.weightage = null;
            });
            this.currentSeverity = {} as SeverityModel;
          } else {
            this.questionResponse.numberOfQuestions = this.oldNoOfQs;
          }
        }
        this.oldNoOfQs = this.questionResponse.numberOfQuestions;
        this.showTable = true;
        this.currentSeverity = this.severities[0];
        this.updateSeverities();
      }
      // console.log(this.questionResponse.numberOfQuestions)
    }
  }

  nextSeverity() {
    const currentQnPerSeverity = this.questionsPerSeverity.find(x => x.severityRating === this.currentSeverity.severityRating);
    const selectedQuestions = currentQnPerSeverity && currentQnPerSeverity.questions ? currentQnPerSeverity.questions : [];
    if (selectedQuestions.length < this.currentSeverity.questionDsbnAsPerSeverityCAP) {
      const info = {
        msg : 'Please select ' + this.currentSeverity.questionDsbnAsPerSeverityCAP + ' questions',
        type : 'Alert',
        };
      this.alertDialog(info);
      return;
    }
    const indexOfCurrent = this.severities.indexOf(this.currentSeverity);
    if (indexOfCurrent === 2) {
      return;
    } else {
      if (currentQnPerSeverity && currentQnPerSeverity.questions) {
        this.questions = [];
        const firstSeverity = this.questionsPerSeverity.find(x => x.severityRating === 3);
        const secondSeverity = this.questionsPerSeverity.find(x => x.severityRating === 2);
        const thirdSeverity = this.questionsPerSeverity.find(x => x.severityRating === 1);
        this.originalQuestions.forEach(origQn => {
          const in_sev1_questions = firstSeverity && firstSeverity.questions ? firstSeverity.questions.indexOf(origQn) : -1;
          const in_sev2_questions = secondSeverity && secondSeverity.questions ? secondSeverity.questions.indexOf(origQn) : -1;
          const in_sev3_questions = thirdSeverity && thirdSeverity.questions ? thirdSeverity.questions.indexOf(origQn) : -1;
          if (in_sev1_questions === -1 && in_sev2_questions === -1 && in_sev3_questions === -1) {
            origQn.isSelected = false;
            this.questions.push(origQn);
            // this.questionData.push(origQn);
            // this.questions.data = this.questionData;
          }
        });
      }
      this.currentSeverity = this.severities[indexOfCurrent + 1];
      const qps = this.questionsPerSeverity.find(x => x.severityRating === this.currentSeverity.severityRating);
      if (qps && qps.questions && this.currentSeverity.questionDsbnAsPerSeverityCAP === qps.questions.length) {
        qps.questions.forEach(q => {
          this.questions.unshift(q);
        });
        this.questions.forEach(qn => {
          const cur = qps.questions.find(x => x.question_id === qn.question_id);
          if (cur) {
            qn.isSelected = true;
          }
        });
      }
    }
  }

  previousSeverity() {
    const currentQnPerSeverity = this.questionsPerSeverity.find(x => x.severityRating === this.currentSeverity.severityRating);
    const indexOfCurrent = this.severities.indexOf(this.currentSeverity);
    if (indexOfCurrent === 0) {
      return;
    } else {
      if (currentQnPerSeverity && currentQnPerSeverity.questions) {
        this.questions = [];
        const firstSeverity = this.questionsPerSeverity.find(x => x.severityRating === 3);
        const secondSeverity = this.questionsPerSeverity.find(x => x.severityRating === 2);
        const thirdSeverity = this.questionsPerSeverity.find(x => x.severityRating === 1);
        this.originalQuestions.forEach(origQn => {
          const in_sev1_questions = firstSeverity && firstSeverity.questions ? firstSeverity.questions.indexOf(origQn) : -1;
          const in_sev2_questions = secondSeverity && secondSeverity.questions ? secondSeverity.questions.indexOf(origQn) : -1;
          const in_sev3_questions = thirdSeverity && thirdSeverity.questions ? thirdSeverity.questions.indexOf(origQn) : -1;
          if (in_sev1_questions === -1 && in_sev2_questions === -1 && in_sev3_questions === -1) {
            origQn.isSelected = false;
            this.questions.push(origQn);
          }
        });
      }
    }
    this.currentSeverity = this.severities[indexOfCurrent - 1];
    const qps = this.questionsPerSeverity.find(x => x.severityRating === this.currentSeverity.severityRating);
    if (qps && qps.questions && this.currentSeverity.questionDsbnAsPerSeverityCAP === qps.questions.length) {
      qps.questions.forEach(q => {
        this.questions.unshift(q);
      });
      this.questions.forEach(qn => {
        const cur = qps.questions.find(x => x.question_id === qn.question_id);
        if (cur) {
          qn.isSelected = true;
        }
      });
    }
    this.questions.data = this.questionData;
    this.table.renderRows();
}

  getProject() {
    this.adminService.getProjectData(this.projectId, this.mangerId).subscribe(
      (response: any) => {
        this.projectData = response;
        this.reporties = response.notified_users;
        this.managers = response.question_assigned_mangers;
      }
    );
  }
  openMDialog(obj) {
    this.dialog.open(MngDataDialogComponent, {
      width: '750px',
      data: obj
    });
  }
  getMngD(mngId) {
    this.adminService.getProjectData(this.projectId , mngId).subscribe(
      (response: any) => {
        this.openMDialog(response);
      }
    );
  }



  arrayShuffle(a) {
    const us: string[] = [];
    a.forEach(element => {
      us.push(element);
    });
    return us;
  }

  getQuestionsData() {
    this.spinner.show();
    this.adminService.getQuestions().subscribe(
      (response: any) => {
        this.questionData = response.data;
        this.questions = new MatTableDataSource(response.data);
        this.questions.paginator = this.paginator;
        this.questions.sort = this.sort;
        this.originalQuestions = response.data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.questions ? this.questions.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.questions.forEach(qn => qn.isSelected = false);
    } else {
      this.questions.forEach(row => this.selection.select(row));
      this.questions.forEach(qn => qn.isSelected = true);
    }
  }
  filter = (d: Date): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 6;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ProjectData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  onSave() {
    const selectedQuestions: any[] = [];
    this.questionsPerSeverity.forEach(qnPerSevr => {
      qnPerSevr.questions.forEach(qn => {
        selectedQuestions.push(qn);
      });
    });
    if (selectedQuestions.length !== this.questionResponse.numberOfQuestions) {
      const info = {
        msg : 'Please select questions for all severeties',
        type : 'Alert',
        };
      this.alertDialog(info);
      return;
    }

    const request: SendQuestions = {
      // tslint:disable-next-line:arrow-return-shorthand
      selected_questions: selectedQuestions.map((x) => { return { question_id: x.question_id, weightage: x.weightage }; }),
      notified_users: this.questionResponse.notified_users,
      // tslint:disable-next-line:max-line-length
      due_date:  this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate() + ' '+ this.fromDate.getHours()+ ':' + this.fromDate.getMinutes()+ ':' + this.fromDate.getSeconds(),
      project_id: this.projectId,
      manager_id: this.mangerId,
    };
    console.log(request);
    this.router.navigate(['/admin/templates']);
  }

}

export interface ProjectData {
  question: string;
  id: number;
  Category: string;
  Bu: string;
  SubBu: string;
  Project: string;
  notified_users: [];
  date: string;
  weightage: string;
  numberOfQuestions: number;
}
export interface SendQuestions {
  selected_questions: any[];
  notified_users: any[];
  due_date: string;
  project_id: number;
  manager_id: number;
}

export interface SeverityModel {
  severityRating: number;
  severityRatingLevelWiseCAP: number;
  weightageRange: number;
  questionDsbnAsPerSeverityCAP?: number;
  weightagePerQuestion?: number;
}

export interface QuestionPerSeverity {
  severityRating: number;
  questions: any[];
}
