import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent } from '../component/question-dialog/question-dialog.component';
import { AdminService } from 'src/app/services/admin.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, NgForm } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import { ConfirmDialogModel, ConfirmationsDialogComponent } from '../component/confirmations-dialog/confirmations-dialog.component';
import { DialogComponent } from '../component/dialog/dialog.component';
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class AdminQuestionsComponent implements OnInit {
  form: FormGroup;
  // displayedColumns: string[] = ['slno', 'category_name', 'question_name', 'action'];
  displayedColumns: string[] = ['select' , 'category_name', 'question_name', 'action'];
  questions: any[];
  selection = new SelectionModel(true, []);
  // dataSource: MatTableDataSource<Questions>;
  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true  }) sort: MatSort;
  // questions: any[];
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  result;
  constructor(public dialog: MatDialog, public adminService: AdminService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getQuestions();
  }
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
  applyQFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openQDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      width: '650px',
      data: obj,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        this.addQuestion(result.data);
      } else if (result.event === 'Update') {
        this.editQuestion(result.data);
      } else if (result.event === 'Delete') {
        this.deleteQuestions(result.data);
      }
    });
  }
  getQuestions() {
    this.spinner.show();
    this.adminService.getQuestions().subscribe(
      (response: any) => {
        this.questions = response.data;
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // this.dataSource.data = this.questions;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  isAllSelected() {
    if (this.dataSource) {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData ? this.dataSource.filteredData.length : 0;
    return numSelected === numRows;
  }
  }
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  async removeSelectedRows() {
    const message = `Are you sure, want to delete selected rows?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmationsDialogComponent, {
      width: '400px',
      data: dialogData
    });
    this.result = await dialogRef.afterClosed().toPromise();
    if (this.result) {
      const qId = [];
      this.selection.selected.forEach(item => {
      qId.push(item.question_id);
      });
      // console.log(qId);
      if (qId.length > 0) {
        let checkOnce = true;
        // console.log(checkOnce);
        qId.forEach(element => {
          this.adminService.deletequestions(element).subscribe(
            (data: any) => {
              // this.getQuestions();
              if (data.message === 'Question Deleted Successfully') {
                const currentQuestion = this.questions.find(x => x.element === element);
                const questionIndex = this.questions.indexOf(currentQuestion);
                this.questions.splice(questionIndex, 1);
                this.dataSource.data = this.questions;
                this.table.renderRows();
              }
              if (checkOnce) {
              if (data.message === 'Question Already Associates with Assigned Question') {
                checkOnce = false;
                const info = {
                  msg: 'In selected rows some of questions is already associates with Assigned Question that questions can\'t be deleted.',
                  type: 'Alert',
                };
                this.alertDialog(info);
              }
            }
            }
          );
        });
        this.selection = new SelectionModel<Element>(true, []);
      }
    }
  }
  addQuestion(question) {
    const request = {
      question_name: question.ques.question_name,
      category_name: question.ques.category_name,
      sub_questions: question.ques.sub_questions,
      // sub_questions: question.options,
    };
    // console.log(request);
    this.adminService.createQuestions(request).subscribe(
      (data: any) => {
        if (data.message === 'The Question you have provided is already there in Question bank.') {
          const info = {
            msg : 'This question has already been created. Duplicate Questions are not allowed.',
            type : 'Info',
            };
          this.alertDialog(info);
        }
        const newQuestion = {
          question_id: data.ques_id,
          question_name: question.ques.question_name,
          category_name: question.ques.category_name,
          sub_questions: question.ques.sub_questions,
          // sub_questions: question.options,
        };
        this.questions.push(newQuestion);
        this.dataSource.data = this.questions;
        this.table.renderRows();
        this.getQuestions();
      },
      (error) => {
      }
    );
  }
  editQuestion(question) {
    const request = {
      // id: question.id,
      question_name: question.ques.question_name,
      category_name: question.ques.category_name,
      sub_questions: question.ques.sub_questions,
    };
    this.adminService.editQuestions(request, question.ques.question_id).subscribe(
      (data) => {
        const currentQuestion = this.questions.find(x => x.question_id === question.ques.question_id);
        const questionIndex = this.questions.indexOf(currentQuestion);
        this.questions[questionIndex] = question.ques;
        this.dataSource.data = this.questions;
        this.table.renderRows();
        this.getQuestions();
      }
    );
  }
  deleteQuestions(question) {
    this.adminService.deletequestions(question.ques.question_id).subscribe(
      (data: any) => {
        // console.log(data.message);
        if (data.message === 'Question Deleted Successfully') {
          const currentQuestion = this.questions.find(x => x.question_id === question.ques.question_id);
          const questionIndex = this.questions.indexOf(currentQuestion);
          this.questions.splice(questionIndex, 1);
          this.dataSource.data = this.questions;
          this.table.renderRows();
        }
        if (data.message === 'Question Already Associates with Assigned Question') {
          const info = {
            msg: 'Sorry, this question can\'t be deleted as it is already assigned to the managers.',
            type: 'Alert',
          };
          this.alertDialog(info);
        }
      },
      (error) => {

      }
    );
  }
}
