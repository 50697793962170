import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkedSubq'
})
export class CheckedSubqPipe implements PipeTransform {

  transform(allSubQ: any[]): any {
    return allSubQ.filter(x => x.answer === 'TRUE');
  }

}
