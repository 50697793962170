import { Component, OnInit } from '@angular/core';
import { MultiDataSet, Label, Color } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { AdminService } from '../services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { element } from 'protractor';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminComponent implements OnInit {
  columnsToDisplay = ['name', 'weight', 'symbol', 'position'];
  displayedColumns: string[] = ['SL.NO', 'BU', 'SubBU', 'project', 'name', 'HrPartner'];
  displayedColumns2: string[] = ['SL.NO', 'BU', 'SubBU', 'projectname', 'manager', 'HrPartner', 'duedate', 'NoOfEmp', 'pendingSince', 'status', ' '];
  displayedColumns3: string[] = ['SL.NO', 'BU', 'SubBU', 'projectname', 'manager', 'HrPartner', 'duedate', 'pendingSince', 'status',];
  expandedElement: AssignedProject | null
  assignedQOriginal: AssignedProject[] = [];
  assignedQ: AssignedProject[] = [];
  buFilter: string[] = [];
  subBuFilter: string[] = [];
  hrPartnerFilter: string[] = [];
  proFilter: string[] = [];
  manFilter: string[] = [];
  statusFilter: string[] = [];
  assignedHrFilter: string[] = [];

  assignedBuFilter;
  assignedSubBuFilter;
  assignedHrPartnerFilter;
  assignedProjectFilter;
  assignedManagerFilter;
  statusDataFilter;
  assignedHrDataFilter;

  // projectList: Projects[] = [];
  buData: Projects[] = [];
  buOriginalData: Projects[] = [];
  buFilters: string[] = [];
  subBuFilters: string[] = [];
  projectFilters: string[] = [];
  managerFilter: string[] = [];
  hrpartnerFilter: string[] = [];

  businesFilter;
  subBusinesFilter;
  projectDataFilter;
  managerDataFilter;
  hrDataFilter;

  pendingOrgProj: PendingProject[] = [];
  pendingProj: PendingProject[] = [];
  pendingBuFilter: string[] = [];
  pendingSubBuFilter: string[] = [];
  pendingProFilter: string[] = [];
  pendingManFilter: string[] = [];
  pendingStatusFilter: string[] = [];
  pendingHrFilter: string[] = [];
  penBuFilter;
  penSubBuFilter;
  pendingProjectFilter;
  pendingManagerFilter;
  pendingstatusDataFilter;
  pendingHrDataFilter;
  searchCtrl = '';
  searchbuCtrl = '';
  searchSubBuCtrl = '';
  searchHrPartnerCtrl = '';
  searchProjectCtrl = '';
  searchManagerCtrl = '';
  searchHrCtrl = '';

  tempBu: Projects[] = [];
  tempSubBu: Projects[] = [];
  tempProjBu: Projects[] = [];
  tempManagerBu: Projects[] = [];
  tempHr: Projects[] = [];

  tempAssignQues: AssignedProject[] = [];
  tempAssiMng: AssignedProject[] = [];
  assignedtempProjBu: AssignedProject[] = [];
  tempAsignBu: AssignedProject[] = [];
  tempAsignSubBu: AssignedProject[] = [];
  tempAsignHrPartner: AssignedProject[] = [];

  tempPendingBu: PendingProject[] = [];
  tempPendingSub: PendingProject[] = [];
  tempPendingProj: PendingProject[] = [];
  tempPendingMngr: PendingProject[] = [];
  pendingtempProjStatus: PendingProject[] = [];
  tempPendingHr: PendingProject[] = [];
  // Doughnut
  public doughnutChartLabels: Label[] = ['Green', 'Red', 'Amber'];
  public colors: any = [{
    // borderColor: 'red',
    backgroundColor: ['#7ED321', '#FE0000', '#FFA200'],
    // pointBackgroundColor: 'orange',
    // pointBorderColor: 'blue,'
  }];
  public doughnutChartData: MultiDataSet = [
    [15, 5, 5],
  ];
  public doughnutChartType: ChartType = 'doughnut';

  constructor(public adminService: AdminService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getCurrentMonthProject();
    // this.getPendingProjectList();
  }

  currentMonthExport() {
    this.adminService.exportExcel(this.assignedQ, 'current-month-assQ');
  }

  pendingAndOverdueExport() {
    this.adminService.exportExcel(this.pendingProj, 'pending-overdue-assQ');
  }

  onTabChange(event) {
    if (event.index === 0) {
      this.getCurrentMonthProject();
    }
    if (event.index === 1) {
      this.getPendingProjectList();
    }
    if (event.index === 2) {
      this.getAdminDashboardData();
    }
  }
  getAdminDashboardData() {
    this.spinner.show();
    this.adminService.getProjectList().subscribe(
      (response: any) => {
        this.spinner.hide();
        this.buOriginalData = response.data;
        this.buData = response.data;
        this.prepareInitialFilters();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  getCurrentMonthProject() {
    this.spinner.show();
    this.adminService.getAssignedQ().subscribe(
      (response: any) => {
        this.spinner.hide();
        this.assignedQOriginal = response.ass_data;
        this.assignedQ = response.ass_data;
        this.prepareAssignInitialFilter();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  getPendingProjectList() {
    this.spinner.show();
    this.adminService.getPendingProjectList().subscribe(
      (response: any) => {
        this.spinner.hide();
        this.pendingOrgProj = response.pending_data;
        this.pendingProj = response.pending_data;
        this.preparePendingInitialFilter();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  // filter Project list
  prepareInitialFilters() {
    this.buData.forEach(element => {
      if (this.buFilters.indexOf(element.bu_name) === -1) {
        this.buFilters.push(element.bu_name);
      }
      if (this.subBuFilters.indexOf(element.sub_bu_name) === -1) {
        this.subBuFilters.push(element.sub_bu_name);
      }
      if (this.projectFilters.indexOf(element.project_name) === -1) {
        this.projectFilters.push(element.project_name);
      }
      if (this.managerFilter.indexOf(element.manager_name) === -1) {
        this.managerFilter.push(element.manager_name);
      }
      if (this.hrPartnerFilter.indexOf(element.hr_partner_name) === -1) {
        this.hrPartnerFilter.push(element.hr_partner_name);
      }
    });
  }

  prepareSubFilters() {
    this.subBusinesFilter = '';
    this.subBuFilters = [];
    const currentSubFilters = this.buData.map((a) => a.sub_bu_name);
    currentSubFilters.forEach(subfilter => {
      if (this.subBuFilters.indexOf(subfilter) === -1) {
        this.subBuFilters.push(subfilter);
      }
    });
  }
  prepareProjFilters() {
    this.projectDataFilter = '';
    this.projectFilters = [];
    const currentProjFilters = this.buData.map((a) => a.project_name);
    currentProjFilters.forEach(projfilter => {
      if (this.projectFilters.indexOf(projfilter) === -1) {
        this.projectFilters.push(projfilter);
      }
    });
  }
  prepareManagerFilters() {
    this.managerDataFilter = '';
    this.managerFilter = [];
    const currentManagerFilters = this.buData.map((a) => a.manager_name);
    currentManagerFilters.forEach(managerFilter => {
      if (this.managerFilter.indexOf(managerFilter) === -1) {
        this.managerFilter.push(managerFilter);
      }
    });
  }
  prepareHrPartnerFilter() {
    this.hrDataFilter = '';
    this.hrPartnerFilter = [];
    const currentHrFilter = this.buData.map((a) => a.hr_partner_name);
    currentHrFilter.forEach(element => {
      if (this.hrPartnerFilter.indexOf(element) === -1) {
        this.hrPartnerFilter.push(element)
      }
    });
  }
  clearFilter() {
    this.businesFilter = this.subBusinesFilter = this.projectDataFilter = this.managerDataFilter = this.hrDataFilter = '';
    this.buData = this.buOriginalData;
    this.prepareInitialFilters();
  }

  isNullOrEmpty(val) {
    return !val || val === '';
  }
  bu(event, obj) {
    if (obj === 'businesUnit') {
      this.onFilterChange(0);
    } else if (obj === 'subBusinesUnit') {
      this.onFilterChange(1);
    } else if (obj === 'project') {
      this.onFilterChange(2);
    } else if (obj === 'manager') {
      this.onFilterChange(3);
    } else {
      this.onFilterChange(4);
    }
  }
  onFilterChange(type: filterType) {
    switch (type) {
      case filterType.bu:
        this.tempBu = [];
        const bu = Object.values(this.businesFilter);
        if (bu.length > 0) {
          bu.forEach(element => {
            const businessUnit = this.buOriginalData.filter(x => x.bu_name === element);
            businessUnit.forEach(ele => {
              this.tempBu.push(ele);
            });
          });
        } else {
          this.tempBu = this.buOriginalData;
        }
        this.buData = this.tempBu;
        this.prepareSubFilters();
        this.prepareProjFilters();
        this.prepareManagerFilters();
        this.prepareHrPartnerFilter();
        break;
      case filterType.subbu:
        this.tempSubBu = [];
        const subBu = Object.values(this.subBusinesFilter);
        if (this.tempBu.length > 0) {
          if (subBu.length > 0) {
            subBu.forEach(element => {
              const subBusiness = this.tempBu.filter(x => x.sub_bu_name === element);
              subBusiness.forEach(ele => {
                this.tempSubBu.push(ele);
              });
            });
          } else {
            this.tempSubBu = this.tempBu;
            this.subBusinesFilter = '';
            this.tempBu = [];
          }
        } else if (subBu.length > 0) {
          this.tempBu = [];
          subBu.forEach(element => {
            const subBusiness = this.buOriginalData.filter(x => x.sub_bu_name === element);
            subBusiness.forEach(ele => {
              this.tempSubBu.push(ele);
            });
          });
        } else {
          this.tempSubBu = this.buOriginalData;
          this.clearFilter();
        }
        this.buData = this.tempSubBu;
        this.prepareProjFilters();
        this.prepareManagerFilters();
        this.prepareHrPartnerFilter();
        break;
      case filterType.project:
        this.tempProjBu = [];
        const projects = Object.values(this.projectDataFilter);
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0) {
          if (projects.length > 0) {
            projects.forEach(element => {
              const proj = this.tempSubBu.filter(x => x.project_name === element);
              proj.forEach(ele => {
                this.tempProjBu.push(ele);
              });
            });
          } else {
            this.tempProjBu = this.tempSubBu;
            this.projectDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempBu.length > 0) {
          if (projects.length > 0) {
            projects.forEach(element => {
              const proj = this.tempBu.filter(x => x.project_name === element);
              proj.forEach(ele => {
                this.tempProjBu.push(ele);
              });
            });
          } else {
            this.tempProjBu = this.tempBu;
            this.projectDataFilter = '';
            this.tempBu = [];
          }
        } else if (this.tempSubBu.length > 0) {
          if (projects.length > 0) {
            projects.forEach(element => {
              const proj = this.tempSubBu.filter(x => x.project_name === element);
              proj.forEach(ele => {
                this.tempProjBu.push(ele);
              });
            });
          } else {
            this.tempProjBu = this.tempSubBu;
            this.projectDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (projects.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          projects.forEach(element => {
            const proj = this.buOriginalData.filter(x => x.project_name === element);
            proj.forEach(ele => {
              this.tempProjBu.push(ele);
            });
          });
        } else {
          this.tempProjBu = this.buOriginalData;
          this.clearFilter();
        }
        this.buData = this.tempProjBu;
        this.prepareManagerFilters();
        this.prepareHrPartnerFilter();
        break;
      case filterType.manager:
        this.tempManagerBu = [];
        const manager = Object.values(this.managerDataFilter);
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProjBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const mng = this.tempProjBu.filter(x => x.manager_name === element);
              mng.forEach(ele => {
                this.tempManagerBu.push(ele);
              });
            });
          } else {
            this.tempManagerBu = this.tempProjBu;
            this.managerDataFilter = '';
            this.tempProjBu = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const mng = this.tempSubBu.filter(x => x.manager_name === element);
              mng.forEach(ele => {
                this.tempManagerBu.push(ele);
              });
            });
          } else {
            this.tempManagerBu = this.tempSubBu;
            this.managerDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempBu.length > 0 && this.tempProjBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const mng = this.tempProjBu.filter(x => x.manager_name === element);
              mng.forEach(ele => {
                this.tempManagerBu.push(ele);
              });
            });
          } else {
            this.tempManagerBu = this.tempProjBu;
            this.managerDataFilter = '';
            this.tempProjBu = [];
          }
        } else if (this.tempSubBu.length > 0 && this.tempProjBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const mng = this.tempProjBu.filter(x => x.manager_name === element);
              mng.forEach(ele => {
                this.tempManagerBu.push(ele);
              });
            });
          } else {
            this.tempManagerBu = this.tempProjBu;
            this.managerDataFilter = '';
            this.tempProjBu = [];
          }
        } else if (this.tempBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const mng = this.tempBu.filter(x => x.manager_name === element);
              mng.forEach(ele => {
                this.tempManagerBu.push(ele);
              });
            });
          } else {
            this.tempManagerBu = this.tempBu;
            this.managerDataFilter = '';
            this.tempBu = [];
          }
        } else if (this.tempSubBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const mng = this.tempSubBu.filter(x => x.manager_name === element);
              mng.forEach(ele => {
                this.tempManagerBu.push(ele);
              });
            });
          } else {
            this.tempManagerBu = this.tempSubBu;
            this.managerDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempProjBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const mng = this.tempProjBu.filter(x => x.manager_name === element);
              mng.forEach(ele => {
                this.tempManagerBu.push(ele);
              });
            });
          } else {
            this.tempManagerBu = this.tempProjBu;
            this.managerDataFilter = '';
            this.tempProjBu = [];
          }
        } else if (manager.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          this.tempProjBu = [];
          manager.forEach(element => {
            const mng = this.buOriginalData.filter(x => x.manager_name === element);
            mng.forEach(ele => {
              this.tempManagerBu.push(ele);
            });
          });
        } else {
          this.tempManagerBu = this.buOriginalData;
          this.clearFilter();
        }
        this.buData = this.tempManagerBu;
        this.prepareHrPartnerFilter();
        break;
      case filterType.hrPartner:
        this.tempHr = [];
        const hr = Object.values(this.hrDataFilter);
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProjBu.length > 0 && this.tempManagerBu) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempManagerBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempManagerBu;
            this.hrDataFilter = '';
            this.tempManagerBu = []
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempManagerBu) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempManagerBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempManagerBu;
            this.hrDataFilter = '';
            this.tempManagerBu = []
          }
        } else if (this.tempBu.length > 0 && this.tempProjBu.length > 0 && this.tempManagerBu) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempManagerBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempManagerBu;
            this.hrDataFilter = '';
            this.tempManagerBu = []
          }
        } else if (this.tempSubBu.length > 0 && this.tempProjBu.length > 0 && this.tempManagerBu) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempManagerBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempManagerBu;
            this.hrDataFilter = '';
            this.tempManagerBu = []
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProjBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempProjBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempProjBu;
            this.hrDataFilter = '';
            this.tempProjBu = []
          }
        } else if (this.tempBu.length > 0 && this.tempProjBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempProjBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempProjBu;
            this.hrDataFilter = '';
            this.tempProjBu = []
          }
        } else if (this.tempSubBu.length > 0 && this.tempProjBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempProjBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempProjBu;
            this.hrDataFilter = '';
            this.tempProjBu = []
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempSubBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempSubBu;
            this.hrDataFilter = '';
            this.tempSubBu = []
          }
        } else if (this.tempProjBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempProjBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempProjBu;
            this.hrDataFilter = '';
            this.tempProjBu = []
          }
        } else if (this.tempManagerBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempManagerBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempManagerBu;
            this.hrDataFilter = '';
            this.tempManagerBu = []
          }
        } else if (this.tempSubBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempSubBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempSubBu;
            this.hrDataFilter = '';
            this.tempSubBu = []
          }
        } else if (this.tempBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempHr.push(ele);
              });
            });
          } else {
            this.tempHr = this.tempBu;
            this.hrDataFilter = '';
            this.tempBu = []
          }
        } else if (hr.length > 0) {
          hr.forEach(element => {
            const hrPartner = this.buOriginalData.filter(x => x.hr_partner_name === element);
            hrPartner.forEach(ele => {
              this.tempHr.push(ele);
            });
          });
        } else {
          this.tempHr = this.buOriginalData;
          this.clearFilter();
        }
        this.buData = this.tempHr;
        break;
      default:
        break;
    }
  }
  // fileter Assigned Project
  prepareAssignInitialFilter() {
    this.assignedQ.forEach(element => {
      if (this.buFilter.indexOf(element.bu_name) === -1) {
        this.buFilter.push(element.bu_name);
      }
      if (this.subBuFilter.indexOf(element.sub_bu_name) === -1) {
        this.subBuFilter.push(element.sub_bu_name)
      }
      if (this.hrPartnerFilter.indexOf(element.hr_partner_name) === -1) {
        this.hrPartnerFilter.push(element.hr_partner_name)
      }
      if (this.proFilter.indexOf(element.project_name) === -1) {
        this.proFilter.push(element.project_name);
      }
      if (this.manFilter.indexOf(element.manager_name) === -1) {
        this.manFilter.push(element.manager_name);
      }
      if (this.statusFilter.indexOf(element.status) === -1) {
        this.statusFilter.push(element.status);
      }
      if (this.assignedHrFilter.indexOf(element.hr_partner_name) === -1) {
        this.assignedHrFilter.push(element.hr_partner_name)
      }
    });
  }
  prepareSubBuFilter() {
    this.assignedSubBuFilter = '';
    this.subBuFilter = [];
    const currentAssignedSubBu = this.assignedQ.map((a) => a.sub_bu_name);
    currentAssignedSubBu.forEach(element => {
      if (this.subBuFilter.indexOf(element) === -1) {
        this.subBuFilter.push(element);
      }
    });
  }


  prepareProjectFilter() {
    this.assignedProjectFilter = '';
    this.proFilter = [];
    const currentAssignedProj = this.assignedQ.map((a) => a.project_name);
    currentAssignedProj.forEach(element => {
      if (this.proFilter.indexOf(element) === -1) {
        this.proFilter.push(element);
      }
    });
  }
  prepareManFilter() {
    this.assignedManagerFilter = '';
    this.manFilter = [];
    const currentAssignedManagerFilter = this.assignedQ.map((a) => a.manager_name);
    currentAssignedManagerFilter.forEach(element => {
      if (this.manFilter.indexOf(element) === -1) {
        this.manFilter.push(element);
      }
    });
  }
  prepareStatusFilters() {
    this.statusDataFilter = '';
    this.statusFilter = [];
    const currentStatusFilters = this.assignedQ.map((a) => a.status);
    currentStatusFilters.forEach(statusfilter => {
      if (this.statusFilter.indexOf(statusfilter) === -1) {
        this.statusFilter.push(statusfilter);
      }
    });
  }

  prepareHrAssignedPartnerFilter() {
    this.assignedHrDataFilter = ''
    this.assignedHrFilter = [];
    const currentAssignedHrPartner = this.assignedQ.map((a) => a.hr_partner_name);
    currentAssignedHrPartner.forEach(element => {
      if (this.assignedHrFilter.indexOf(element) === -1) {
        this.assignedHrFilter.push(element);
      }
    })
  }


  clearAssignedFilter() {
    this.assignedBuFilter = this.assignedSubBuFilter = this.assignedProjectFilter = this.assignedManagerFilter = this.statusDataFilter = this.assignedHrDataFilter = '';
    this.assignedQ = this.assignedQOriginal;
    this.prepareAssignInitialFilter();
  }
  currentbu(event, obj) {
    if (obj === 'businesUnit') {
      this.assignedonFilterChange(0);
    } else if (obj === 'subBusinesUnit') {
      this.assignedonFilterChange(1);
    } else if (obj === 'project') {
      this.assignedonFilterChange(2);
    } else if (obj === 'manager') {
      this.assignedonFilterChange(3);
    } else if (obj === 'hrPartner') {
      this.assignedonFilterChange(4);
    } else {
      this.assignedonFilterChange(5)
    }
  }
  assignedonFilterChange(type: assignfilterType) {
    switch (type) {
      case assignfilterType.bu:
        this.tempAsignBu = [];
        const assignBu = Object.values(this.assignedBuFilter);
        if (assignBu.length > 0) {
          assignBu.forEach(element => {
            const assignBu = this.assignedQOriginal.filter(x => x.bu_name === element);
            assignBu.forEach(ele => {
              this.tempAsignBu.push(ele);
            });
          });
        } else {
          this.tempAsignBu = this.assignedQOriginal;
          this.clearAssignedFilter()
        }
        this.assignedQ = this.tempAsignBu;
        this.prepareSubBuFilter();
        this.prepareProjectFilter();
        this.prepareManFilter();
        this.prepareStatusFilters();
        this.prepareHrAssignedPartnerFilter();
        break;
      case assignfilterType.subBu:
        this.tempAsignSubBu = []
        const assignSubBu = Object.values(this.assignedSubBuFilter);
        if (this.tempAsignBu.length > 0) {
          if (assignSubBu.length > 0) {
            assignSubBu.forEach(element => {
              const assignSub = this.tempAsignBu.filter(x => x.sub_bu_name === element);
              assignSub.forEach(ele => {
                this.tempAsignSubBu.push(ele);
              });
            });
          } else {
            this.tempAsignSubBu = this.tempAsignBu;
            this.assignedSubBuFilter = ''
            this.tempAsignBu = []
          }
        } else if (assignSubBu.length > 0) {
          assignSubBu.forEach(element => {
            const assignSub = this.assignedQOriginal.filter(x => x.sub_bu_name === element);
            assignSub.forEach(ele => {
              this.tempAsignSubBu.push(ele);
            });
          });
        } else {
          this.tempAsignSubBu = this.assignedQOriginal;
          this.clearAssignedFilter();
        }
        this.assignedQ = this.tempAsignSubBu;
        this.prepareProjectFilter();
        this.prepareManFilter();
        this.prepareStatusFilters();
        this.prepareHrAssignedPartnerFilter();
        break;
      case assignfilterType.project:
        this.tempAssignQues = [];
        const assignQuestion = Object.values(this.assignedProjectFilter);
        if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0) {
          if (assignQuestion.length > 0) {
            assignQuestion.forEach(element => {
              const assignedProj = this.tempAsignSubBu.filter(x => x.project_name === element);
              assignedProj.forEach(ele => {
                this.tempAssignQues.push(ele);
              });
            });
          } else {
            this.tempAssignQues = this.tempAsignSubBu;
            this.assignedProjectFilter = '';
            this.tempAsignSubBu = [];
          }
        } else if (this.tempAsignSubBu.length > 0) {
          if (assignQuestion.length > 0) {
            assignQuestion.forEach(element => {
              const assignedProj = this.tempAsignSubBu.filter(x => x.project_name === element);
              assignedProj.forEach(ele => {
                this.tempAssignQues.push(ele);
              });
            });
          } else {
            this.tempAssignQues = this.tempAsignSubBu;
            this.assignedProjectFilter = '';
            this.tempAsignSubBu = [];
          }
        } else if (this.tempAsignBu.length > 0) {
          if (assignQuestion.length > 0) {
            assignQuestion.forEach(element => {
              const assignedProj = this.tempAsignBu.filter(x => x.project_name === element);
              assignedProj.forEach(ele => {
                this.tempAssignQues.push(ele);
              });
            });
          } else {
            this.tempAssignQues = this.tempAsignBu;
            this.assignedProjectFilter = '';
            this.tempAsignBu = [];
          }
        } else if (assignQuestion.length > 0) {
          assignQuestion.forEach(element => {
            const assignedProj = this.assignedQOriginal.filter(x => x.project_name === element);
            assignedProj.forEach(ele => {
              this.tempAssignQues.push(ele);
            });
          });
        } else {
          this.tempAssignQues = this.assignedQOriginal;
          this.clearAssignedFilter();
        }
        this.assignedQ = this.tempAssignQues;
        this.prepareManFilter();
        this.prepareStatusFilters();
        this.prepareHrAssignedPartnerFilter();
        break;
      case assignfilterType.manager:
        this.tempAssiMng = [];
        const assignedManager = Object.values(this.assignedManagerFilter);
        if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0) {
          if (assignedManager.length > 0) {
            assignedManager.forEach(element => {
              const mnger = this.tempAssignQues.filter(x => x.manager_name === element);
              mnger.forEach(ele => {
                this.tempAssiMng.push(ele);
              });
            });
          } else {
            this.tempAssiMng = this.tempAssignQues;
            this.assignedManagerFilter = '';
            this.tempAssignQues = [];
          }
        } else if (this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0) {
          if (assignedManager.length > 0) {
            assignedManager.forEach(element => {
              const mnger = this.tempAssignQues.filter(x => x.manager_name === element);
              mnger.forEach(ele => {
                this.tempAssiMng.push(ele);
              });
            });
          } else {
            this.tempAssiMng = this.tempAssignQues;
            this.assignedManagerFilter = '';
            this.tempAssignQues = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssignQues.length > 0) {
          if (assignedManager.length > 0) {
            assignedManager.forEach(element => {
              const mnger = this.tempAssignQues.filter(x => x.manager_name === element);
              mnger.forEach(ele => {
                this.tempAssiMng.push(ele);
              });
            });
          } else {
            this.tempAssiMng = this.tempAssignQues;
            this.assignedManagerFilter = '';
            this.tempAssignQues = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0) {
          if (assignedManager.length > 0) {
            assignedManager.forEach(element => {
              const mnger = this.tempAsignSubBu.filter(x => x.manager_name === element);
              mnger.forEach(ele => {
                this.tempAssiMng.push(ele);
              });
            });
          } else {
            this.tempAssiMng = this.tempAsignSubBu;
            this.assignedManagerFilter = '';
            this.tempAsignSubBu = [];
          }
        } else if (this.tempAsignBu.length > 0) {
          if (assignedManager.length > 0) {
            assignedManager.forEach(element => {
              const mnger = this.tempAsignBu.filter(x => x.manager_name === element);
              mnger.forEach(ele => {
                this.tempAssiMng.push(ele);
              });
            });
          } else {
            this.tempAssiMng = this.tempAsignBu;
            this.assignedManagerFilter = '';
            this.tempAsignBu = [];
          }
        } else if (this.tempAsignSubBu.length > 0) {
          if (assignedManager.length > 0) {
            assignedManager.forEach(element => {
              const mnger = this.tempAsignSubBu.filter(x => x.manager_name === element);
              mnger.forEach(ele => {
                this.tempAssiMng.push(ele);
              });
            });
          } else {
            this.tempAssiMng = this.tempAsignSubBu;
            this.assignedManagerFilter = '';
            this.tempAsignSubBu = [];
          }
        } else if (this.tempAssignQues.length > 0) {
          if (assignedManager.length > 0) {
            assignedManager.forEach(element => {
              const mnger = this.tempAssignQues.filter(x => x.manager_name === element);
              mnger.forEach(ele => {
                this.tempAssiMng.push(ele);
              });
            });
          } else {
            this.tempAssiMng = this.tempAssignQues;
            this.assignedManagerFilter = '';
            this.tempAssignQues = [];
          }
        } else if (assignedManager.length > 0) {
          this.tempAssignQues = [];
          assignedManager.forEach(element => {
            const mnger = this.assignedQOriginal.filter(x => x.manager_name === element);
            mnger.forEach(ele => {
              this.tempAssiMng.push(ele);
            });
          });
        } else {
          this.tempAssiMng = this.assignedQOriginal;
          this.clearAssignedFilter();
        }
        this.assignedQ = this.tempAssiMng;
        this.prepareStatusFilters();
        this.prepareHrAssignedPartnerFilter();
        break;
      case assignfilterType.hrPartner:
        this.tempAsignHrPartner = [];
        const hr = Object.values(this.assignedHrDataFilter);
        if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssignQues.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssignQues;
            this.tempAssignQues = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignSubBu.length > 0 && this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAsignSubBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAsignSubBu;
            this.tempAsignSubBu = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssignQues.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssignQues.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssignQues;
            this.tempAssignQues = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAssiMng.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssiMng.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssiMng;
            this.tempAssiMng = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAssignQues.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAssignQues.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAssignQues;
            this.tempAssignQues = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignSubBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAsignSubBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAsignSubBu;
            this.tempAsignSubBu = [];
            this.assignedHrDataFilter = '';
          }
        } else if (this.tempAsignBu.length > 0) {
          if (hr.length > 0) {
            hr.forEach(element => {
              const hrPartner = this.tempAsignBu.filter(x => x.hr_partner_name === element);
              hrPartner.forEach(ele => {
                this.tempAsignHrPartner.push(ele)
              });
            });
          } else {
            this.tempAsignHrPartner = this.tempAsignBu;
            this.tempAsignBu = [];
            this.assignedHrDataFilter = '';
          }
        } else if (hr.length > 0) {
          hr.forEach(element => {
            const hrPartner = this.assignedQOriginal.filter(x => x.hr_partner_name === element);
            hrPartner.forEach(ele => {
              this.tempAsignHrPartner.push(ele)
            });
          });
        } else {
          this.tempAsignHrPartner = this.assignedQOriginal;
          this.clearAssignedFilter();
        }
        this.assignedQ = this.tempAsignHrPartner;
        this.prepareStatusFilters();
        break;
      case assignfilterType.status:
        this.assignedtempProjBu = [];
        const assignedProject = Object.values(this.statusDataFilter);
        if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssiMng.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssiMng.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAssiMng.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAssignQues.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignSubBu.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignHrPartner.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignHrPartner.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignHrPartner;
            this.statusDataFilter = '';
            this.tempAsignHrPartner = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0 && this.tempAssiMng.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAssiMng.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAssiMng;
            this.statusDataFilter = '';
            this.tempAssiMng = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssiMng.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAssiMng.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAssiMng;
            this.statusDataFilter = '';
            this.tempAssiMng = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssiMng.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAssiMng.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAssiMng;
            this.statusDataFilter = '';
            this.tempAssiMng = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAssignQues.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAssignQues;
            this.statusDataFilter = '';
            this.tempAssignQues = [];
          }
        } else if (this.tempAsignSubBu.length > 0 && this.tempAssignQues.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAssignQues.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAssignQues;
            this.statusDataFilter = '';
            this.tempAssignQues = [];
          }
        } else if (this.tempAsignBu.length > 0 && this.tempAssignQues.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAssignQues.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAssignQues;
            this.statusDataFilter = '';
            this.tempAssignQues = [];
          }
        }
        else if (this.tempAsignBu.length > 0 && this.tempAsignSubBu.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignSubBu.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignSubBu;
            this.statusDataFilter = '';
            this.tempAsignSubBu = [];
          }
        } else if (this.tempAsignSubBu.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignSubBu.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignSubBu;
            this.statusDataFilter = '';
            this.tempAsignSubBu = [];
          }
        } else if (this.tempAsignBu.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAsignBu.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAsignBu;
            this.statusDataFilter = '';
            this.tempAsignBu = [];
          }
        } else if (this.tempAssignQues.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAssignQues.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAssignQues;
            this.statusDataFilter = '';
            this.tempAssignQues = [];
          }
        } else if (this.tempAssiMng.length > 0) {
          if (assignedProject.length > 0) {
            assignedProject.forEach(element => {
              const prj = this.tempAssiMng.filter(x => x.status === element);
              prj.forEach(ele => {
                this.assignedtempProjBu.push(ele);
              });
            });
          } else {
            this.assignedtempProjBu = this.tempAssiMng;
            this.statusDataFilter = '';
            this.tempAssiMng = [];
          }
        } else if (assignedProject.length > 0) {
          this.tempAssiMng = [];
          this.tempAssignQues = [];
          assignedProject.forEach(element => {
            const prj = this.assignedQOriginal.filter(x => x.status === element);
            prj.forEach(ele => {
              this.assignedtempProjBu.push(ele);
            });
          });
        } else {
          this.assignedtempProjBu = this.assignedQOriginal;
          this.clearAssignedFilter();
        }
        this.assignedQ = this.assignedtempProjBu;
        break;
      default:
        break;
    }
  }

  // filter Pending Project
  preparePendingInitialFilter() {
    this.pendingProj.forEach(element => {
      if (this.pendingBuFilter.indexOf(element.bu_name) === -1) {
        this.pendingBuFilter.push(element.bu_name);
      }
      if (this.pendingSubBuFilter.indexOf(element.sub_bu_name) === -1) {
        this.pendingSubBuFilter.push(element.sub_bu_name);
      }
      if (this.pendingProFilter.indexOf(element.project_name) === -1) {
        this.pendingProFilter.push(element.project_name);
      }
      if (this.pendingManFilter.indexOf(element.manager_name) === -1) {
        this.pendingManFilter.push(element.manager_name);
      }
      if (this.pendingStatusFilter.indexOf(element.status) === -1) {
        this.pendingStatusFilter.push(element.status);
      }
      if (this.pendingHrFilter.indexOf(element.hr_partner_name) === -1) {
        this.pendingHrFilter.push(element.hr_partner_name);
      }
    });
  }

  preparePendingSubBu() {
    this.penSubBuFilter = '';
    this.pendingSubBuFilter = [];
    const currentPendingSubBu = this.pendingProj.map((a) => a.sub_bu_name);
    currentPendingSubBu.forEach(element => {
      if (this.pendingSubBuFilter.indexOf(element) === -1) {
        this.pendingSubBuFilter.push(element)
      }
    });
  }
  preparePendingProjFilter() {
    this.pendingProjectFilter = '';
    this.pendingProFilter = [];
    const currentPendingProj = this.pendingProj.map((a) => a.project_name);
    currentPendingProj.forEach(element => {
      if (this.pendingProFilter.indexOf(element) === -1) {
        this.pendingProFilter.push(element);
      }
    });
  }

  preparePendingManFilter() {
    this.pendingManagerFilter = '';
    this.pendingManFilter = [];
    const currentPendingManagerFilter = this.pendingProj.map((a) => a.manager_name);
    currentPendingManagerFilter.forEach(element => {
      if (this.pendingManFilter.indexOf(element) === -1) {
        this.pendingManFilter.push(element);
      }
    });
  }
  preparePendingStatusFilters() {
    this.pendingstatusDataFilter = '';
    this.pendingStatusFilter = [];
    const currentPendingStatusFilters = this.pendingProj.map((a) => a.status);
    currentPendingStatusFilters.forEach(statusfilter => {
      if (this.pendingStatusFilter.indexOf(statusfilter) === -1) {
        this.pendingStatusFilter.push(statusfilter);
      }
    });
  }
  peparePendingHr() {
    this.pendingHrDataFilter = '';
    this.pendingHrFilter = [];
    const currentPendingHr = this.pendingProj.map((a) => a.hr_partner_name);
    currentPendingHr.forEach(element => {
      if (this.pendingHrFilter.indexOf(element) === -1) {
        this.pendingHrFilter.push(element);
      }
    });
  }


  clearPendingFilter() {
    this.penBuFilter = this.penSubBuFilter = this.pendingProjectFilter = this.pendingManagerFilter = this.pendingstatusDataFilter = this.pendingHrDataFilter = '';
    this.pendingProj = this.pendingOrgProj;
    this.preparePendingInitialFilter();
  }
  pendingbu(event, obj) {
    if (obj === 'businesUnit') {
      this.pendingOnFilterChange(0);
    } else if (obj === 'subBusinesUnit') {
      this.pendingOnFilterChange(1);
    } else if (obj === 'pendingProj') {
      this.pendingOnFilterChange(2);
    } else if (obj === 'pendingManager') {
      this.pendingOnFilterChange(3);
    } else if (obj === 'pendingHr') {
      this.pendingOnFilterChange(4);
    } else {
      this.pendingOnFilterChange(5);
    }
  }
  pendingOnFilterChange(type: pendingfilterType) {
    switch (type) {
      case pendingfilterType.bu:
        this.tempPendingBu = [];
        const bu = Object.values(this.penBuFilter);
        if (bu.length > 0) {
          bu.forEach(element => {
            const business = this.pendingOrgProj.filter(x => x.bu_name === element);
            business.forEach(ele => {
              this.tempPendingBu.push(ele);
            });
          });
        } else {
          this.tempPendingBu = this.pendingOrgProj;
        }
        this.pendingProj = this.tempPendingBu;
        this.preparePendingSubBu();
        this.preparePendingProjFilter();
        this.preparePendingManFilter();
        this.preparePendingStatusFilters();
        this.peparePendingHr();
        break;
      case pendingfilterType.subBu:
        this.tempPendingSub = [];
        const business = Object.values(this.penSubBuFilter);
        if (this.tempPendingBu.length > 0) {
          if (business.length > 0) {
            business.forEach(element => {
              const sBusiness = this.tempPendingBu.filter(x => x.sub_bu_name === element);
              sBusiness.forEach(ele => {
                this.tempPendingSub.push(ele);
              });
            });
          } else {
            this.tempPendingSub = this.tempPendingBu;
            this.penSubBuFilter = '';
            this.tempPendingBu = [];
          }
        } else if (business.length > 0) {
          business.forEach(element => {
            const business = this.pendingOrgProj.filter(x => x.sub_bu_name === element);
            business.forEach(ele => {
              this.tempPendingSub.push(ele);
            });
          });
        } else {
          this.tempPendingSub = this.pendingOrgProj;
          this.clearPendingFilter();
        }
        this.pendingProj = this.tempPendingSub;
        this.preparePendingProjFilter();
        this.preparePendingManFilter();
        this.preparePendingStatusFilters();
        this.peparePendingHr();
        break;
      case pendingfilterType.project:
        this.tempPendingProj = [];
        const project = Object.values(this.pendingProjectFilter);
        if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0) {
          if (project.length > 0) {
            project.forEach(element => {
              const prj = this.tempPendingSub.filter(x => x.project_name === element);
              prj.forEach(ele => {
                this.tempPendingProj.push(ele);
              });
            });
          } else {
            this.tempPendingProj = this.tempPendingSub;
            this.pendingProjectFilter = '';
            this.tempPendingSub = []
          }
        } else if (this.tempPendingSub.length > 0) {
          if (project.length > 0) {
            project.forEach(element => {
              const prj = this.tempPendingSub.filter(x => x.project_name === element);
              prj.forEach(ele => {
                this.tempPendingProj.push(ele);
              });
            });
          } else {
            this.tempPendingProj = this.tempPendingSub;
            this.pendingProjectFilter = '';
            this.tempPendingSub = []
          }
        } else if (this.tempPendingBu.length > 0) {
          if (project.length > 0) {
            project.forEach(element => {
              const prj = this.tempPendingBu.filter(x => x.project_name === element);
              prj.forEach(ele => {
                this.tempPendingProj.push(ele);
              });
            });
          } else {
            this.tempPendingProj = this.tempPendingBu;
            this.pendingProjectFilter = '';
            this.tempPendingBu = []
          }
        } else if (project.length > 0) {
          project.forEach(element => {
            const prj = this.pendingOrgProj.filter(x => x.project_name === element);
            prj.forEach(ele => {
              this.tempPendingProj.push(ele);
            });
          });
        } else {
          this.tempPendingProj = this.pendingOrgProj;
          this.clearPendingFilter();
        }
        this.pendingProj = this.tempPendingProj;
        this.preparePendingManFilter();
        this.preparePendingStatusFilters();
        this.peparePendingHr();
        break;
      case pendingfilterType.manager:
        this.tempPendingMngr = [];
        const mngr = Object.values(this.pendingManagerFilter);
        if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0) {
          if (mngr.length > 0) {
            mngr.forEach(element => {
              const manager = this.tempPendingProj.filter(x => x.manager_name === element);
              manager.forEach(ele => {
                this.tempPendingMngr.push(ele);
              });
            });
          } else {
            this.tempPendingMngr = this.tempPendingProj;
            this.pendingManagerFilter = '';
            this.tempPendingProj = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingProj.length > 0) {
          if (mngr.length > 0) {
            mngr.forEach(element => {
              const manager = this.tempPendingProj.filter(x => x.manager_name === element);
              manager.forEach(ele => {
                this.tempPendingMngr.push(ele);
              });
            });
          } else {
            this.tempPendingMngr = this.tempPendingProj;
            this.pendingManagerFilter = '';
            this.tempPendingProj = [];
          }
        } else if (this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0) {
          if (mngr.length > 0) {
            mngr.forEach(element => {
              const manager = this.tempPendingProj.filter(x => x.manager_name === element);
              manager.forEach(ele => {
                this.tempPendingMngr.push(ele);
              });
            });
          } else {
            this.tempPendingMngr = this.tempPendingProj;
            this.pendingManagerFilter = '';
            this.tempPendingProj = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0) {
          if (mngr.length > 0) {
            mngr.forEach(element => {
              const manager = this.tempPendingSub.filter(x => x.manager_name === element);
              manager.forEach(ele => {
                this.tempPendingMngr.push(ele);
              });
            });
          } else {
            this.tempPendingMngr = this.tempPendingSub;
            this.pendingManagerFilter = '';
            this.tempPendingSub = [];
          }
        } else if (this.tempPendingBu.length > 0) {
          if (mngr.length > 0) {
            mngr.forEach(element => {
              const manager = this.tempPendingBu.filter(x => x.manager_name === element);
              manager.forEach(ele => {
                this.tempPendingMngr.push(ele);
              });
            });
          } else {
            this.tempPendingMngr = this.tempPendingBu;
            this.pendingManagerFilter = '';
            this.tempPendingBu = [];
          }
        } else if (this.tempPendingProj.length > 0) {
          if (mngr.length > 0) {
            mngr.forEach(element => {
              const manager = this.tempPendingProj.filter(x => x.manager_name === element);
              manager.forEach(ele => {
                this.tempPendingMngr.push(ele);
              });
            });
          } else {
            this.tempPendingMngr = this.tempPendingProj;
            this.pendingManagerFilter = '';
            this.tempPendingProj = [];
          }
        } else if (mngr.length > 0) {
          this.tempPendingProj = [];
          mngr.forEach(element => {
            const manager = this.pendingOrgProj.filter(x => x.manager_name === element);
            manager.forEach(ele => {
              this.tempPendingMngr.push(ele);
            });
          });
        } else {
          this.tempPendingMngr = this.pendingOrgProj;
          this.clearPendingFilter();
        }
        this.pendingProj = this.tempPendingMngr;
        this.preparePendingStatusFilters();
        this.peparePendingHr();
        break;
      case pendingfilterType.hrPartner:
        this.tempPendingHr = []
        const hrPartner = Object.values(this.pendingHrDataFilter)
        if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingMngr.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingMngr;
            this.tempPendingMngr = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingMngr.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingMngr.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingMngr;
            this.tempPendingMngr = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingMngr.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingMngr;
            this.tempPendingMngr = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingMngr.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingMngr;
            this.tempPendingMngr = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingProj.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingProj;
            this.tempPendingProj = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingSub.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingSub;
            this.tempPendingSub = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingBu.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingBu.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingBu;
            this.tempPendingBu = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingSub.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingSub.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingSub;
            this.tempPendingSub = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingProj.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingProj.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingProj;
            this.tempPendingProj = [];
            this.pendingHrDataFilter = ''
          }
        } else if (this.tempPendingMngr.length > 0) {
          if (hrPartner.length > 0) {
            hrPartner.forEach(element => {
              const hr = this.tempPendingMngr.filter(x => x.hr_partner_name === element);
              hr.forEach(ele => {
                this.tempPendingHr.push(ele)
              });
            });
          } else {
            this.tempPendingHr = this.tempPendingMngr;
            this.tempPendingMngr = [];
            this.pendingHrDataFilter = ''
          }
        } else if (hrPartner.length > 0) {
          hrPartner.forEach(element => {
            const hr = this.pendingOrgProj.filter(x => x.hr_partner_name === element);
            hr.forEach(ele => {
              this.tempPendingHr.push(ele)
            });
          });
        } else {
          this.tempPendingHr = this.pendingOrgProj;
        }
        this.pendingProj = this.tempPendingHr;
        this.preparePendingStatusFilters();
        break;
      case pendingfilterType.status:
        this.pendingtempProjStatus = [];
        const status = Object.values(this.pendingstatusDataFilter);
        if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0 && this.tempPendingHr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingHr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingHr;
            this.pendingManagerFilter = '';
            this.tempPendingHr = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingHr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingHr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingHr;
            this.pendingManagerFilter = '';
            this.tempPendingHr = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingMngr.length > 0 && this.tempPendingHr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingHr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingHr;
            this.pendingManagerFilter = '';
            this.tempPendingHr = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0 && this.tempPendingHr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingHr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingHr;
            this.pendingManagerFilter = '';
            this.tempPendingHr = [];
          }
        } else if (this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0 && this.tempPendingHr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingHr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingHr;
            this.pendingManagerFilter = '';
            this.tempPendingHr = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingMngr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingMngr;
            this.pendingManagerFilter = '';
            this.tempPendingMngr = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingSub.length > 0 && this.tempPendingMngr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingMngr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingMngr;
            this.pendingManagerFilter = '';
            this.tempPendingMngr = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingMngr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingMngr;
            this.pendingManagerFilter = '';
            this.tempPendingMngr = [];
          }
        } else if (this.tempPendingSub.length > 0 && this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingMngr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingMngr;
            this.pendingManagerFilter = '';
            this.tempPendingMngr = [];
          }
        } else if (this.tempPendingBu.length > 0 && this.tempPendingMngr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingMngr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingMngr;
            this.pendingManagerFilter = '';
            this.tempPendingMngr = [];
          }
        } else if (this.tempPendingSub.length > 0 && 0 && this.tempPendingMngr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingMngr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingMngr;
            this.pendingManagerFilter = '';
            this.tempPendingMngr = [];
          }
        } else if (this.tempPendingProj.length > 0 && this.tempPendingMngr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingMngr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingMngr;
            this.pendingManagerFilter = '';
            this.tempPendingMngr = [];
          }
        } else if (this.tempPendingBu.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingBu.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingBu;
            this.pendingManagerFilter = '';
            this.tempPendingBu = [];
          }
        } else if (this.tempPendingSub.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingSub.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingSub;
            this.pendingManagerFilter = '';
            this.tempPendingSub = [];
          }
        } else if (this.tempPendingProj.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingProj.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingProj;
            this.pendingManagerFilter = '';
            this.tempPendingProj = [];
          }
        } else if (this.tempPendingMngr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingMngr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingMngr;
            this.pendingManagerFilter = '';
            this.tempPendingMngr = [];
          }
        } else if (this.tempPendingHr.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const sts = this.tempPendingHr.filter(x => x.status === element);
              sts.forEach(ele => {
                this.pendingtempProjStatus.push(ele);
              });
            });
          } else {
            this.pendingtempProjStatus = this.tempPendingHr;
            this.pendingManagerFilter = '';
            this.tempPendingHr = [];
          }
        } else if (status.length > 0) {
          this.tempPendingProj = [];
          this.tempPendingMngr = [];
          status.forEach(element => {
            const sts = this.pendingOrgProj.filter(x => x.status === element);
            sts.forEach(ele => {
              this.pendingtempProjStatus.push(ele);
            });
          });
        } else {
          this.pendingtempProjStatus = this.pendingOrgProj;
          this.clearPendingFilter();
        }
        this.pendingProj = this.pendingtempProjStatus;
        break;
      default:
        break;
    }
  }
}
export interface AssignedProject {
  bu_name: string;
  sub_bu_name: string;
  project_name: string;
  manager_name: string;
  due_date: string;
  no_of_employees: number;
  status: string;
  hr_partner_name: string;
  pending_since: number;
}

export interface Projects {
  bu_name: string;
  sub_bu_name: string;
  manager_name: string;
  project_name: string;
  hr_partner_name: string;
}

export interface PendingProject {
  bu_name: string;
  sub_bu_name: string;
  project_name: string;
  manager_name: string;
  due_date: string;
  status: string;
  hr_partner_name: string;
  pending_since: number;
}

export enum filterType {
  bu,
  subbu,
  project,
  manager,
  hrPartner,
}

export enum assignfilterType {
  bu,
  subBu,
  project,
  manager,
  hrPartner,
  status,
}

export enum pendingfilterType {
  bu,
  subBu,
  project,
  manager,
  hrPartner,
  status,
}
