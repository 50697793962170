import { Component, OnInit } from '@angular/core';
import { MultiDataSet, Label, Color } from 'ng2-charts';
import { Title } from '@angular/platform-browser';
import { ManagerService } from '../services/manager.service';
import { Observable, from } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartType } from 'chart.js';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ManagerComponent implements OnInit {

  public doughnutChartLabels: Label[] = ['Green', 'Red', 'Amber'];
  public colors: any = [{
    backgroundColor: ['#7ED321', '#FE0000', '#FFA200'],
  }];
  public doughnutChartData: MultiDataSet = [
    [15, 5, 5],
  ];
  public none: Label[] = ['none'];
  public NA: any = [{
    backgroundColor: ['#999f93']
  }];

  public doughnutChartType: ChartType = 'doughnut';

  reportData: ReportData[] = [];
  statusDATA: ReportData[] = [];
  yearFilter: ReportData[] = [];
  statuses: string[] = [] as string[];
  questionStatuses: string[] = [] as string[];
  yearlyData;
  columnsToDisplay = ['org_emp_id', 'emp_name', 'designation', 'question_answerred_status', 'current_status' ,
  'updated_status', 'updated_by', ' '];
  months = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  expandedElement: ReportData | null;
  pendingCount;
  naCount;
  greenCount;
  amberCount;
  redCount;
  chartGreenCount;
  chartAmberCount;
  chartRedCount;
  statusOfQ;
  dueDate;
  noOfDay;
  overDueDate;
  responseData;
  constructor(public title: Title, public managerService: ManagerService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.title.setTitle('Manager Dashboard');
    this.getReport();
    // this.test();
    // this.getCurrentMonth();
  }
  filter() {
    // tslint:disable-next-line:only-arrow-functions
    const allStatuses = this.reportData.map(function (item) { return item.question_answerred_status; });
    allStatuses.forEach(status => {
      const index = this.statuses.indexOf(status);
      if (index === -1) {
        this.statuses.push(status);
      }
    });

    // tslint:disable-next-line:only-arrow-functions
    const allQstnStatuses = this.reportData.map(function (item) { return item.current_status; });
    allQstnStatuses.forEach(status => {
      // console.log(status);
      const index = this.questionStatuses.indexOf(status);
      // console.log(index);
      if (index === -1) {
        this.questionStatuses.push(status);
      }
    });
  }

  getDataElement(filter: string) {
    this.reportData = this.statusDATA.filter(x => x.question_answerred_status === filter);
    // console.log(this.reportData);
  }

  getQstnDataElement(filter: string) {
    this.reportData = this.statusDATA.filter(x => x.current_status === filter);
  }
  getCount(character: any) {
    if (this.naCount === 0) {
      this.pendingCount = this.statusDATA.filter(x => x.question_answerred_status === character).length;
    } else {
      this.pendingCount = 'NA';
    }
    if (this.naCount === this.reportData.length) {
      return this.statusOfQ = true;
    }
  }
  getNaCount(character: any) {
    this.naCount = this.statusDATA.filter(x => x.question_answerred_status === character).length;
    // console.log(this.naCount);
  }
  greenDCount(character: any) {
    this.greenCount = this.statusDATA.filter(x => x.current_status === character).length;
    this.chartGreenCount = this.statusDATA.filter(x => x.status_for_chart === character).length;
  }
  redDCount(character: any) {
    this.redCount = this.statusDATA.filter(x => x.current_status === character).length;
    this.chartRedCount = this.statusDATA.filter(x => x.status_for_chart === character).length;
  }
  amberDCount(character: any) {
    this.amberCount = this.statusDATA.filter(x => x.current_status === character).length;
    this.chartAmberCount = this.statusDATA.filter(x => x.status_for_chart === character).length;

  }

  getReport() {
    this.spinner.show();
    this.managerService.employeeReport().subscribe(
      (response: any) => {
        this.spinner.hide();
        this.overDueDate = response.over_due_data;
        this.responseData = response;
        this.reportData = response.data;
        // console.log(this.reportData.length);
        this.statusDATA = response.data;
        this.filter();
        this.getNaCount('NA');
        this.getCount('Pending');
        this.greenDCount('Green');
        this.redDCount('Red');
        this.amberDCount('Amber');
        this.dueDate = moment(response.data[0].due_date);
        const currentDate = new Date();
        // const d: any = moment(currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()
        // + ' ' + currentDate.getHours() + ':' + currentDate.getMinutes() + ':' + currentDate.getSeconds());
        const d: any = moment(currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()
        + ' ' + 0 + ':' + 0 + ':' + 0);
        this.noOfDay = this.dueDate.diff(d, 'days');
        // console.log(d);
        // console.log(this.dueDate);

      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  groupBy(arr, prop) {
    const map = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
}
}

export interface ReportData {
  'emp_name': string;
  'org_emp_id': number;
  'profile_picure': string;
  'designation': string;
  'question_answerred_status': string;
  'current_status': string;
  'updated_status': string;
  'status_for_chart': string;
  'updated_by': string;
  'status_history': [];
  'due_date': string;
}
