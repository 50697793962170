import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import { AdminService } from 'src/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessService } from 'src/app/services/business.service';
import { Socialusers } from 'src/app/models/socialusers';

@Component({
  selector: 'app-emp-report',
  templateUrl: './emp-report.component.html',
  styleUrls: ['./emp-report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EmpReportComponent implements OnInit {
fromDate;
report;
empQuestions;
date = new FormControl(moment());
managerId;
projectId;
socialusers = new Socialusers();
  constructor(public adminService: AdminService, private spinner: NgxSpinnerService, public businessService: BusinessService) { }

  ngOnInit() {
    const currentDate = this.date.value._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    this.managerId = this.socialusers ? this.socialusers.id : undefined;
    this.projectId = this.socialusers ? this.socialusers.project_id : undefined;
    // console.log(this.projectId);
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);

    const currentDate = ctrlValue.month(normalizedMonth.month())._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);

    datepicker.close();
  }
  getEmploye() {
    // console.log(this.buData[0].manager_id);
    this.spinner.show();
    const request = {
      manager_id : this.managerId,
      report_date : this.fromDate,
    };
    console.log(request);
    this.adminService.individualEmp(request, this.projectId).subscribe(
      (response) => {
        this.report = response;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getEmpQ(t) {
    // console.log(t);
    this.spinner.show();
    const request = {
      manager_id : t.manager_id,
      // report_date: this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate(),
      //  + ' ' + this.fromDate.getHours()+ ':' + this.fromDate.getMinutes()+ ':' + this.fromDate.getSeconds()
      report_date: this.fromDate,
      emp_id: t.emp_id,
    };
    this.adminService.idvidualEmpQ(request, t.project_id).subscribe(
      (response) => {
        this.empQuestions = response;
        // console.log(this.empQuestions);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
}
