import { Component, OnInit, ViewChild } from '@angular/core';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
const moment = _rollupMoment || _moment;
import { ExportToCsv } from 'export-to-csv';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTable } from '@angular/material/table';


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-l2-actions-report',
  templateUrl: './l2-actions-report.component.html',
  styleUrls: ['./l2-actions-report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class L2ActionsReportComponent implements OnInit {
  fromDate;
  displayedColumns =
    // tslint:disable-next-line:max-line-length
    ['EmpName', 'Email', 'Designation', 'ManagerName', 'Project', 'OriginalStatus', 'MonthAndYear', 'L2_Manager_name', 'HRPartner', 'UpdatedStatus', 'UpdatedBy', 'Comment', 'UpdatedDate', 'AnyActionTaken'];
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  date = new FormControl(moment());
  ragData: Ragstatus[] = [];
  orgRagData: Ragstatus[] = [];

  buFilters: string[] = [];
  subBuFilters: string[] = [];
  employeeFilter: string[] = [];
  projectFilters: string[] = [];
  managerFilter: string[] = [];
  statusFilter: string[] = [];

  searchCtrl = '';
  searchbuCtrl = '';
  searchSubBuCtrl = '';
  searchEmployeeCtrl = '';
  searchProjectCtrl = '';
  searchManagerCtrl = '';

  tempBu: Ragstatus[] = [];
  tempSubBu: Ragstatus[] = [];
  tempEmployee: Ragstatus[] = [];
  tempProject: Ragstatus[] = [];
  tempManager: Ragstatus[] = [];
  tempStatus: Ragstatus[] = [];

  buDataFilter;
  subBuDataFilter;
  projectDataFilter;
  managerDataFilter;
  employeeDataFilter;
  statusDataFilter;
  constructor(public adminService: AdminService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    const currentDate = this.date.value._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);
    // console.log(this.fromDate);
    this.getReport();
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);

    const currentDate = ctrlValue.month(normalizedMonth.month())._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);

    datepicker.close();
  }

  getReport() {
    this.spinner.show();
    this.adminService.getRagStatus(this.fromDate).subscribe(
      (res: any) => {
        this.orgRagData = res.data;
        this.ragData = res.data;
        this.prepareInitialFilters();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  prepareInitialFilters() {
    this.ragData.forEach(element => {
      if (this.buFilters.indexOf(element.bu) === -1) {
        this.buFilters.push(element.bu);
      }
      if (this.subBuFilters.indexOf(element.sub_bu) === -1) {
        this.subBuFilters.push(element.sub_bu);
      }
      if (this.employeeFilter.indexOf(element.emp_name) === -1) {
        this.employeeFilter.push(element.emp_name);
      }
      if (this.projectFilters.indexOf(element.project) === -1) {
        this.projectFilters.push(element.project);
      }
      if (this.managerFilter.indexOf(element.manager_name) === -1) {
        this.managerFilter.push(element.manager_name);
      }
      if (this.statusFilter.indexOf(element.status) === -1) {
        this.statusFilter.push(element.status);
      }
    });
  }
  prepareSubFilters() {
    this.subBuDataFilter = '';
    this.subBuFilters = [];
    const currentSubFilters = this.ragData.map((a) => a.sub_bu);
    currentSubFilters.forEach(subfilter => {
      if (this.subBuFilters.indexOf(subfilter) === -1) {
        this.subBuFilters.push(subfilter);
      }
    });
  }

  prepareEmployeeFilter() {
    this.employeeDataFilter = '';
    this.employeeFilter = [];
    const currentEmpFilter = this.ragData.map((a) => a.emp_name);
    currentEmpFilter.forEach(empfilter => {
      if (this.employeeFilter.indexOf(empfilter) === -1) {
        this.employeeFilter.push(empfilter);
      }
    });
  }

  prepareProjFilters() {
    this.projectDataFilter = '';
    this.projectFilters = [];
    const currentProjFilters = this.ragData.map((a) => a.project);
    currentProjFilters.forEach(projfilter => {
      if (this.projectFilters.indexOf(projfilter) === -1) {
        this.projectFilters.push(projfilter);
      }
    });
  }
  prepareManagerFilters() {
    this.managerDataFilter = '';
    this.managerFilter = [];
    const currentManagerFilters = this.ragData.map((a) => a.manager_name);
    currentManagerFilters.forEach(managerFilter => {
      if (this.managerFilter.indexOf(managerFilter) === -1) {
        this.managerFilter.push(managerFilter);
      }
    });
  }
  prepareStatusFilters() {
    this.statusDataFilter = '';
    this.statusFilter = [];
    const currentStatusFilters = this.ragData.map((a) => a.status);
    currentStatusFilters.forEach(statusfilter => {
      if (this.statusFilter.indexOf(statusfilter) === -1) {
        this.statusFilter.push(statusfilter);
      }
    });
  }
  clearFilter() {
    // tslint:disable-next-line:max-line-length
    this.buDataFilter = this.subBuDataFilter = this.projectDataFilter = this.managerDataFilter = this.employeeDataFilter = this.statusDataFilter = '';
    this.ragData = this.orgRagData;
    this.prepareInitialFilters();
  }
  isNullOrEmpty(val) {
    return !val || val === '';
  }
  bu(event, obj) {
    // console.log(obj);
    // console.log(event);
    if (obj === 'businesUnit') {
      this.onFilterChange(0);
    } else if (obj === 'subBusinesUnit') {
      this.onFilterChange(1);
    } else if (obj === 'project') {
      this.onFilterChange(2);
    } else if (obj === 'manager') {
      this.onFilterChange(3);
    } else if (obj === 'employee') {
      this.onFilterChange(4);
    } else {
      this.onFilterChange(5);
    }
  }
  onFilterChange(type: filterType) {
    switch (type) {
      case filterType.bu:
        this.tempBu = [];
        const bu = Object.values(this.buDataFilter);
        if (bu.length > 0) {
          bu.forEach(element => {
            const businessUnit = this.orgRagData.filter(x => x.bu === element);
            businessUnit.forEach(ele => {
              this.tempBu.push(ele);
            });
          });
        } else {
          this.tempBu = this.orgRagData;
        }
        this.ragData = this.tempBu;
        this.prepareSubFilters();
        this.prepareProjFilters();
        this.prepareManagerFilters();
        this.prepareEmployeeFilter();
        this.prepareStatusFilters();
        break;
      case filterType.subbu:
        this.tempSubBu = [];
        const subBu = Object.values(this.subBuDataFilter);
        if (this.tempBu.length > 0) {
          if (subBu.length > 0) {
            subBu.forEach(element => {
              const subBusiness = this.tempBu.filter(x => x.sub_bu === element);
              subBusiness.forEach(ele => {
                this.tempSubBu.push(ele);
              });
            });
          } else {
            this.tempSubBu = this.tempBu;
            this.subBuDataFilter = '';
            this.tempBu = [];
          }
        } else if (subBu.length > 0) {
          this.tempBu = [];
          subBu.forEach(element => {
            const subBusiness = this.orgRagData.filter(x => x.sub_bu === element);
            subBusiness.forEach(ele => {
              this.tempSubBu.push(ele);
            });
          });
        } else {
          this.tempSubBu = this.orgRagData;
          this.clearFilter();
        }
        this.ragData = this.tempSubBu;
        this.prepareProjFilters();
        this.prepareManagerFilters();
        this.prepareEmployeeFilter();
        this.prepareStatusFilters();
        break;
      case filterType.project:
        this.tempProject = [];
        const project = Object.values(this.projectDataFilter);
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0) {
          if (project.length > 0) {
            project.forEach(element => {
              const pr = this.tempSubBu.filter(x => x.project === element);
              pr.forEach(ele => {
                this.tempProject.push(ele);
              });
            });
          } else {
            this.tempProject = this.tempSubBu;
            this.projectDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempBu.length > 0) {
          if (project.length > 0) {
            project.forEach(element => {
              const pr = this.tempBu.filter(x => x.project === element);
              pr.forEach(ele => {
                this.tempProject.push(ele);
              });
            });
          } else {
            this.tempProject = this.tempBu;
            this.projectDataFilter = '';
            this.tempBu = [];
          }
        } else if (project.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          project.forEach(element => {
            const pr = this.orgRagData.filter(x => x.project === element);
            pr.forEach(ele => {
              this.tempProject.push(ele);
            });
          });
        } else {
          this.tempProject = this.orgRagData;
          this.clearFilter();
        }
        this.ragData = this.tempProject;
        this.prepareManagerFilters();
        this.prepareEmployeeFilter();
        this.prepareStatusFilters();
        break;
      case filterType.manager:
        this.tempManager = [];
        const manager = Object.values(this.managerDataFilter);
        if (this.tempBu.length > 0 && this.tempSubBu.length && this.tempProject.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const pr = this.tempProject.filter(x => x.manager_name === element);
              pr.forEach(ele => {
                this.tempManager.push(ele);
              });
            });
          } else {
            this.tempManager = this.tempProject;
            this.managerDataFilter = '';
            this.tempProject = [];
          }
        } else if (this.tempBu.length > 0 && this.tempProject.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const pr = this.tempProject.filter(x => x.manager_name === element);
              pr.forEach(ele => {
                this.tempManager.push(ele);
              });
            });
          } else {
            this.tempManager = this.tempProject;
            this.managerDataFilter = '';
            this.tempProject = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const pr = this.tempSubBu.filter(x => x.manager_name === element);
              pr.forEach(ele => {
                this.tempManager.push(ele);
              });
            });
          } else {
            this.tempManager = this.tempSubBu;
            this.managerDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const pr = this.tempBu.filter(x => x.manager_name === element);
              pr.forEach(ele => {
                this.tempManager.push(ele);
              });
            });
          } else {
            this.tempManager = this.tempBu;
            this.managerDataFilter = '';
            this.tempBu = [];
          }
        } else if (this.tempSubBu.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const pr = this.tempSubBu.filter(x => x.manager_name === element);
              pr.forEach(ele => {
                this.tempManager.push(ele);
              });
            });
          } else {
            this.tempManager = this.tempSubBu;
            this.managerDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempProject.length > 0) {
          if (manager.length > 0) {
            manager.forEach(element => {
              const pr = this.tempProject.filter(x => x.manager_name === element);
              pr.forEach(ele => {
                this.tempManager.push(ele);
              });
            });
          } else {
            this.tempManager = this.tempProject;
            this.managerDataFilter = '';
            this.tempProject = [];
          }
        } else if (manager.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          this.tempManager = [];
          this.tempProject = [];
          manager.forEach(element => {
            const pr = this.orgRagData.filter(x => x.manager_name === element);
            pr.forEach(ele => {
              this.tempManager.push(ele);
            });
          });
        } else {
          this.tempManager = this.orgRagData;
          this.clearFilter();
        }
        this.ragData = this.tempManager;
        this.prepareEmployeeFilter();
        this.prepareStatusFilters();
        break;
      case filterType.employee:
        this.tempEmployee = [];
        const employee = Object.values(this.employeeDataFilter);
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProject.length > 0 && this.tempManager.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempManager.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempManager;
            this.employeeDataFilter = '';
            this.tempManager = [];
          }
        } else if (this.tempBu.length > 0 && this.tempProject.length > 0 && this.tempManager.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempManager.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempManager;
            this.employeeDataFilter = '';
            this.tempManager = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempManager.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempManager.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempManager;
            this.employeeDataFilter = '';
            this.tempManager = [];
          }
        } else if (this.tempBu.length > 0 && this.tempManager.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempManager.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempManager;
            this.employeeDataFilter = '';
            this.tempManager = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempSubBu.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempSubBu;
            this.employeeDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempSubBu.length > 0 && this.tempProject.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempProject.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempProject;
            this.employeeDataFilter = '';
            this.tempProject = [];
          }
        } else if (this.tempSubBu.length > 0 && this.tempManager.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempManager.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempManager;
            this.employeeDataFilter = '';
            this.tempManager = [];
          }
        } else if (this.tempManager.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempManager.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempManager;
            this.employeeDataFilter = '';
            this.tempManager = [];
          }
        } else if (this.tempProject.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempProject.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempProject;
            this.employeeDataFilter = '';
            this.tempProject = [];
          }
        } else if (this.tempBu.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempBu.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempBu;
            this.employeeDataFilter = '';
            this.tempBu = [];
          }
        } else if (this.tempSubBu.length > 0) {
          if (employee.length > 0) {
            employee.forEach(element => {
              const emp = this.tempSubBu.filter(x => x.emp_name === element);
              emp.forEach(ele => {
                this.tempEmployee.push(ele);
              });
            });
          } else {
            this.tempEmployee = this.tempSubBu;
            this.employeeDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (employee.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          this.tempProject = [];
          this.tempManager = [];
          employee.forEach(element => {
            const emp = this.orgRagData.filter(x => x.emp_name === element);
            emp.forEach(ele => {
              this.tempEmployee.push(ele);
            });
          });
        } else {
          this.tempEmployee = this.orgRagData;
        }
        this.ragData = this.tempEmployee;
        this.prepareStatusFilters();
        break;
      case filterType.status:
        this.tempStatus = [];
        const status = Object.values(this.statusDataFilter);
        // tslint:disable-next-line:max-line-length
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProject.length > 0 && this.tempManager.length > 0 && this.tempEmployee.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempEmployee.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempEmployee;
            this.statusDataFilter = '';
            this.tempEmployee = [];
          }
          // tslint:disable-next-line:max-line-length
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProject.length > 0 && this.tempEmployee.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempEmployee.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempEmployee;
            this.statusDataFilter = '';
            this.tempEmployee = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProject.length > 0 && this.tempEmployee.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempEmployee.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempEmployee;
            this.statusDataFilter = '';
            this.tempEmployee = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempEmployee.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempEmployee.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempEmployee;
            this.statusDataFilter = '';
            this.tempEmployee = [];
          }
        } else if (this.tempBu.length > 0 && this.tempEmployee.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempEmployee.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempEmployee;
            this.statusDataFilter = '';
            this.tempEmployee = [];
          }
        } else if (this.tempProject.length > 0 && this.tempManager.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempManager.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempManager;
            this.statusDataFilter = '';
            this.tempManager = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempSubBu.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempSubBu;
            this.statusDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempBu.length > 0 && this.tempProject.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempProject.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempProject;
            this.statusDataFilter = '';
            this.tempProject = [];
          }
        } else if (this.tempSubBu.length > 0 && this.tempProject.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempProject.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempProject;
            this.statusDataFilter = '';
            this.tempProject = [];
          }
        } else if (this.tempBu.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempBu.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempBu;
            this.statusDataFilter = '';
            this.tempBu = [];
          }
        } else if (this.tempSubBu.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempSubBu.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempSubBu;
            this.statusDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempEmployee.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempEmployee.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempEmployee;
            this.statusDataFilter = '';
            this.tempEmployee = [];
          }
        } else if (this.tempProject.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempProject.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempProject;
            this.statusDataFilter = '';
            this.tempProject = [];
          }
        } else if (this.tempManager.length > 0) {
          if (status.length > 0) {
            status.forEach(element => {
              const pr = this.tempManager.filter(x => x.status === element);
              pr.forEach(ele => {
                this.tempStatus.push(ele);
              });
            });
          } else {
            this.tempStatus = this.tempManager;
            this.statusDataFilter = '';
            this.tempManager = [];
          }
        } else if (status.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          this.tempProject = [];
          this.tempManager = [];
          this.tempEmployee = [];
          status.forEach(element => {
            const pr = this.orgRagData.filter(x => x.status === element);
            pr.forEach(ele => {
              this.tempStatus.push(ele);
            });
          });
        } else {
          this.tempStatus = this.orgRagData;
          this.statusDataFilter = '';
        }
        this.ragData = this.tempStatus;
        break;
      default:
        break;
    }
    this.table.renderRows();
  }
  csvGenerator() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.ragData);
  }

}
export interface Ragstatus {
  report_card_id: number;
  emp_id: number;
  emp_name: string;
  Project: string;
  org_emp_id: number;
  designation: string;
  emp_email: string;
  manager_name: string;
  project: string;
  status: string;
  report_month: string;
  l2_managers: string;
  l3_managers: string;
  l4_managers: string;
  l5_managers: string;
  hr_partner: string;
  updated_status: string;
  updated_by: string;
  comments: string;
  updated_on: string;
  action_taken: string;
  level: string;
  grade: string;
  bu: string;
  sub_bu: string;
}

export enum filterType {
  bu,
  subbu,
  project,
  manager,
  employee,
  status
}
