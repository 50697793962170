import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { ConfirmDialogModel, ConfirmationsDialogComponent } from '../component/confirmations-dialog/confirmations-dialog.component';
import { DialogComponent } from '../component/dialog/dialog.component';
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {
@ViewChild('sidenav',  {static: false}) sidenav: MatSidenav;
// tslint:disable-next-line:variable-name
  // tslint:disable-next-line:variable-name
  nav_position = 'end';
  opened = false;
  // tslint:disable-next-line:max-line-length
  displayedColumns: string[] = ['name', 'createdBy', 'createdAt', 'durationCycle', 'startdate', 'duedate', 'enddate', 'noOfManagers', 'action'];
  dataSource;
  template;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true  }) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  tempInfo;
  result;
  hideSpiner = true;
  constructor(public dialog: MatDialog, public router: Router, private spinner: NgxSpinnerService, public adminService: AdminService) {
  }

  ngOnInit() {
    this.hideSpiner = true;
    this.getScheduler();
  }
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
  getScheduler() {
    this.spinner.show();
    this.adminService.getScheduler().subscribe(
      (response: any) => {
        this.template = response.data;
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  open(d) {
    // this.tempInfo = d;
    this.hideSpiner = false;
    this.sidenav.open();
    this.spinner.show();
    this.adminService.getSchedulerById(d.id).subscribe(
     (res: any) => {
       this.spinner.hide();
       this.tempInfo = res.data;
     },
     (error) => {
       this.spinner.hide();
     }
    );
  }
  async deleteScheduler(r) {
    const message = `Are you sure, want to delete this ?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmationsDialogComponent, {
      width: '400px',
      data: dialogData
    });
    this.result = await dialogRef.afterClosed().toPromise();
    if (this.result) {
    this.adminService.deleteScheduler(r.id).subscribe(
      (res: any) => {
        // console.log(res);
        // tslint:disable-next-line:quotemark
        if (res.msg === "Active Scheduler can't be deleted.") {
          const info = {
            msg: 'You can\'t delete this Scheduler. Because currently this Scheduler is active.',
            type: 'Alert',
          };
          this.alertDialog(info);
        } else {
          const currentScheduler = this.template.find(x => x.id === r.id);
          const schedulerIndex = this.template.indexOf(currentScheduler);
          this.template.splice(schedulerIndex, 1);
          this.dataSource = this.template;
          this.table.renderRows();
        }
        // if (res.message === 'Scheduler deleted successfully.') {
        //   const currentScheduler = this.template.find(x => x.id === r.id);
        //   const schedulerIndex = this.template.indexOf(currentScheduler);
        //   this.template.splice(schedulerIndex, 1);
        //   this.dataSource = this.template;
        //   this.table.renderRows();
        // }

      },
      (error) => {

      }
    );
    }
  }
  close() {
    this.sidenav.close();
  }

}

export interface TempData {
  id: number;
  name: string;
  createdBy: string;
  durationCycle: string;
  startDate: Date;
  enddate: Date;
  noOfManagers: number;
}
