import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Socialusers } from 'src/app/models/socialusers';
import { environment } from '../../environments/environment';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  socialusers = new Socialusers();
  public apiBaseUrl = environment.apiBaseUrl;
  token;
  previewQuestions = false;
  constructor(public http: HttpClient) {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    this.token = this.socialusers.access_token;
  }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }


  getQuestions() {
    // console.log('accesstoken '  +  this.token);
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     // tslint:disable-next-line:object-literal-key-quotes
    //     'Authorization': 'Bearer ' + this.token,
    //   })
    // };
    const url = this.apiBaseUrl + 'api/questions';
    return this.http.get(url);
  }
  createQuestions(data) {
    const url = this.apiBaseUrl + 'api/questions';
    return this.http.post(url, data);
  }
  editQuestions(data, id) {
    const url = this.apiBaseUrl + 'api/questions/' + id;
    return this.http.put(url, data);
  }
  deletequestions(id) {
    const url = this.apiBaseUrl + 'api/questions/' + id;
    return this.http.delete(url);
  }
  getCategoriesList() {
    const url = this.apiBaseUrl + 'api/questions/categories';
    return this.http.get(url);
  }

  // get assign and un-assign questions

  //  getAssignQuestions(query?: string) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': 'Bearer ' + this.token,
  //     }),
  //   };
  //   const url = this.apiBaseUrl + 'api/assign-questions' + query;
  //   return this.http.get(url, httpOptions);
  //  }

  getAssignQuestions() {
    const url = this.apiBaseUrl + 'api/assign-questions';
    return this.http.get(url);
  }

  getProjectData(id, mngId) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 1?manager_id=10
        // tslint:disable-next-line:object-literal-key-quotes
        'Authorization': 'Bearer ' + this.token,
      })
    };
    const url = this.apiBaseUrl + 'api/assign-questions/' + id + '?manager_id=' + mngId;
    return this.http.get(url, httpOptions);
  }
  postQuestion(data) {
    const url = this.apiBaseUrl + 'api/assign-questions';
    return this.http.post(url, data);
  }

  getPosteddata(id) {
    const url = this.apiBaseUrl + 'api/assign-questions/' + id;
    return this.http.get(url);
  }
  getAdminDashboard() {
    const url = this.apiBaseUrl + 'api/admin-dashboard';
    return this.http.get(url);
  }
  getProjectList() {
    const url = this.apiBaseUrl + 'api/admin-dashboard?show_project_list=Yes';
    return this.http.get(url);
  }
  getAssignedQ() {
    const url = this.apiBaseUrl + 'api/admin-dashboard?show_current_month_project_status=Yes';
    return this.http.get(url);
  }
  getPendingProjectList() {
    const url = this.apiBaseUrl + 'api/admin-dashboard?show_pending_projects=Yes';
    return this.http.get(url);
  }
  removeSubOptions(id) {
    const url = this.apiBaseUrl + 'api/questions/sub-questions/' + id;
    return this.http.delete(url);
  }
  individualEmp(data, id) {
    const url = this.apiBaseUrl + 'api/individual-report/' + id;
    return this.http.post(url, data);
  }
  idvidualEmpQ(data, id) {
    const url = this.apiBaseUrl + 'api/individual-report/' + id + '?show_questions=Yes';
    return this.http.post(url, data);
  }
  overalReport(data) {
    const url = this.apiBaseUrl + 'api/overall-report';
    return this.http.post(url, data);
  }
  overallProjReport(data) {
    const url = this.apiBaseUrl + 'api/overall-report?show_managers=Yes';
    return this.http.post(url, data);
  }
  createScheduler(data) {
    const url = this.apiBaseUrl + 'api/scheduler';
    return this.http.post(url, data);
  }
  updateScheduler(data) {
    const url = this.apiBaseUrl + 'api/scheduler';
    return this.http.put(url, data);
  }
  deleteScheduler(id) {
    const url = this.apiBaseUrl + 'api/scheduler/' + id;
    return this.http.delete(url);
  }
  getScheduler() {
    const url = this.apiBaseUrl + 'api/scheduler';
    return this.http.get(url);
  }
  getSchedulerById(id) {
    const url = this.apiBaseUrl + 'api/scheduler/' + id;
    return this.http.get(url);
  }
  getRagStatus(date) {
    const url = this.apiBaseUrl + 'api/rag-status?report_month=' + date;
    return this.http.get(url);
  }
  getGrphicalReport(date) {
    const url = this.apiBaseUrl + 'api/graphical-report?report_month=' + date;
    return this.http.get(url)
  }
}
