import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
msg: any;
title;
  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Message) {
    dialogRef.disableClose = true;
    this.msg = data.msg;
    this.title = data.type;
   }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
export interface Message {
  msg: string;
  type: string;
}
