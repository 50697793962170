import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-assigned-questions',
  templateUrl: './assigned-questions.component.html',
  styleUrls: ['./assigned-questions.component.css']
})
export class AssignedQuestionsComponent implements OnInit {

  displayedQuestions: string[] = ['SL.No.', 'question', 'Category', 'Weightage'];
  projectId: number;
  managerId;
  projectData;
  // questions: any[];
  questions;
  originalQuestions: any[];
  questionResponse: ProjectData = {} as ProjectData;
  projectQuestions;
  reporties;
  projectView;

  constructor(public router: Router, public dialog: MatDialog, public activatedRoute: ActivatedRoute,
              public adminService: AdminService, private spinner: NgxSpinnerService
  ) {
  }
  ngOnInit() {
    this.projectId = this.activatedRoute.snapshot.params.id;
    this.managerId = this.activatedRoute.snapshot.params.mngId;
    this.getProject();
    // this.getSubmittedData();
  }
  getProject() {
    this.spinner.show();
    this.adminService.getProjectData(this.projectId, this.managerId).subscribe(
      (response: any) => {
        this.projectData = response;
        this.reporties = response.notified_users;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }


  // getSubmittedData() {
  //   this.spinner.show();
  //   this.adminService.getPosteddata(this.projectId).subscribe(
  //     (response: any) => {
  //       this.projectView = response;
  //       this.spinner.hide();
  //     },
  //     (error) => {
  //       this.spinner.hide();
  //      }
  //   );
  // }

}
export interface ProjectData {
  question: string;
  id: number;
  Category: string;
  Bu: string;
  SubBu: string;
  Project: string;
  notified_users: [];
  date: string;
  weightage: string;
  numberOfQuestions: number;
}

