import { Component, OnInit } from '@angular/core';
import { GoogleLoginProvider, FacebookLoginProvider, AuthService } from 'angular-6-social-login';
// import { Socialusers } from '../Models/socialusers';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Socialusers } from 'src/app/models/socialusers';
import { AuthServices } from '../services/auth.service';
import { DialogComponent } from '../admin/component/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  title = 'EWS';
  response;
  socialusers = new Socialusers();
  token;
  returnUrl: string;
  baseUrl = environment.apiBaseUrl;
  constructor(
    public OAuth: AuthService,
    private authServices: AuthServices,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) { }
  ngOnInit() {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    const role = this.socialusers ? this.socialusers.role_name : undefined;
    // tslint:disable-next-line:max-line-length
    this.returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || ((role === 'admin') ? ('/admin/dashboard') : ((role === 'manager') ? ('/manager/dashboard') : ('/')));
    // console.log(this.returnUrl);
    // console.log(role);
    // if (role[0] === 'Admin' || role[1] === 'Admin') {
    //   this.router.navigate([`/admin/dashboard`]);
    // } else if (role[0] === 'Manager' || role[1] === 'Manager') {
    //   this.router.navigate([`/manager/dashboard`]);
    // } else if (role[0] === 'Employee' || role[1] === 'Employee') {
    //   this.router.navigate([`/`]);
    // }
    if (role) {
      if (role.includes('Admin')) {
        this.router.navigate([`/admin/dashboard`]);
      } else if (role.includes('Manager')) {
        this.router.navigate([`/manager/dashboard`]);
      } else {
        this.router.navigate([`/`]);
      }
    }
  }
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
  public socialSignIn(socialProvider: string) {
    let socialPlatformProvider;
    if (socialProvider === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialProvider === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.OAuth.signIn(socialPlatformProvider).then(socialusers => {
      // console.log(socialProvider, socialusers);
      // console.log(socialusers);
      localStorage.setItem('userinfo', JSON.stringify(socialusers));
      this.savesresponse(socialusers);
    });
  }

  savesresponse(socialusers: Socialusers) {
    if (socialusers.email.indexOf('regalix-inc') !== -1) {
      this.authServices.makeServerSocialLoginCall
        (this.baseUrl + 'api/login', { google_auth_token: socialusers.idToken }).subscribe((res: any) => {
          // console.log(res);
          this.socialusers = res;
          this.response = res.userDetail;
          localStorage.setItem('socialusers', JSON.stringify(this.socialusers));
          // console.log(localStorage.setItem('socialusers', JSON.stringify(this.socialusers)));
          // res.role_name.forEach(element => {
          //   if (element === 'Admin') {
          //     window.location.href = '/admin/dashboard';
          //   } else if (element === 'Manager') {
          //     window.location.href = '/manager/dashboard';
          //   }
          // });
          if (res.role_name.includes('Admin')) {
            window.location.href = '/admin/dashboard';
          } else if (res.role_name.includes('Manager')) {
            window.location.href = '/manager/dashboard';
          } else {
            localStorage.clear();
            const info = {
              msg: 'You don\'t have permission to login.',
              type: 'Alert',
            };
            this.alertDialog(info);
          }
          // if (res.role_name[0] === 'Admin' || res.role_name[1] === 'Admin') {
          //   // this.router.navigate([`/admin/dashboard`]);
          //   window.location.href = '/admin/dashboard';
          // } else if (res.role_name[0] === 'Manager' || res.role_name[1] === 'Manager') {
          //   // this.router.navigate(['/manager/dashboard']);
          //   window.location.href = '/manager/dashboard';
          // } else if (res.role_name[0] === 'Employee' || res.role_name[1] === 'Employee') {
          //   // alert('You don\'nt have permission to login');
          //   localStorage.clear();
          //   const info = {
          //     msg : 'You don\'t have permission to login.',
          //     type : 'Alert',
          //     };
          //   this.alertDialog(info);
          // }
        });
    } else {
      const info = {
        msg: 'You need to be a Regalix staff to access this Application',
        type: 'Alert',
      };
      this.alertDialog(info);
    }

  }
}
