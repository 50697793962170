import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ManagerService } from 'src/app/services/manager.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/shared/constants';
import { DialogComponent } from 'src/app/admin/component/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
// tslint:disable-next-line:max-line-length
import { ConfirmationsDialogComponent, ConfirmDialogModel } from 'src/app/admin/component/confirmations-dialog/confirmations-dialog.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit, AfterViewInit  {
  questions;
  options;
  employeeData;
  categoryFilter: string[] = [];
  currentCategory = '';
  originalQuestions;
  totalNoOfQuestions = 0;
  recordedResponses: PreSubmitResponse[] = [] as PreSubmitResponse[];
  result;
  dueDate;
  getService;
  postService;
  query;
  @ViewChild('tablescroll', {static: false}) tablescroll: ElementRef;
  constructor(public title: Title, public managerService: ManagerService, public activatedRoute: ActivatedRoute,
              public router: Router, private spinner: NgxSpinnerService, public dialog: MatDialog
  ) { }

  ngAfterViewInit() {
    // console.log(this.tablescroll.nativeElement);
}
  ngOnInit() {
    this.title.setTitle('Questions');
    this.dueDate = this.activatedRoute.snapshot.params.date;
    // console.log(this.dueDate);
    if (this.dueDate) {
      this.getService = this.managerService.getPendingQuestions(this.dueDate);
    } else {
      this.getService = this.managerService.getAssignedQuestions();
    }
    this.getQuestions();
    // this.activeCategory();
    this.activatedRoute.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.query = params.get('currentMonth');
        // console.log(this.query);
      }
    );
  }

  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }

  getQuestions() {
    this.spinner.show();
    this.getService.subscribe(
      (respone: any) => {
        this.originalQuestions = respone;
        this.employeeData = respone.data;
        // console.log(this.employeeData.length);
        this.options = Constants.userOptions;
        this.employeeData = respone.data;
        if (this.employeeData.length > 0) {
          respone.data[0].categories.forEach(element => {
            this.categoryFilter.push(element.category_name);
          });
        }
        respone.data.forEach(element => {
          element.categories.forEach(category => {
            category.questions.forEach(question => {
              const newQuestion: FinalQuestionResponse = {
                question_id: question.question_id,
                weightage: question.weightage,
                start_date: question.start_date,
                due_date: question.due_date,
                project_id: question.project_id,
                notify_user: question.notify_user,
                answer: question.answer,
                comments: question.comments,
                sub_questions: question.sub_questions
              };
              const existingRecord = this.recordedResponses.find(x => x.emp_id === element.emp_id);
              if (existingRecord) {
                existingRecord.questions.push(newQuestion);
              } else {
                const questionsArray: FinalQuestionResponse[] = [] as FinalQuestionResponse[];
                questionsArray.push(newQuestion);
                const preSubmitResponse: PreSubmitResponse = {
                  emp_id: element.emp_id,
                  questions: questionsArray
                };
                this.recordedResponses.push(preSubmitResponse);
              }
            });
          });
        });
        this.categoryFilter.sort();
        const firstFilter = this.categoryFilter[0];
        this.currentCategory = firstFilter;
        const tempquestions = this.employeeData.filter(x => x.category_name === firstFilter);
        if (tempquestions && tempquestions.length > 0) {
          this.questions = tempquestions[0].questions;
        }

        this.spinner.hide();
        // console.log(JSON.stringify(this.recordedResponses));
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getQuestionsPerEmployee(employeeId?: number) {
    if (this.originalQuestions) {
      if (this.employeeData.length > 0) {
        const employeeRelatedData = employeeId ? this.employeeData.find(x => x.emp_id === employeeId) : this.employeeData[0];
        return employeeRelatedData.categories.find(x => x.category_name === this.currentCategory).questions;
      }
    }

  }

  onSave() {
    const saveOnlyQuestions: PreSubmitResponse[] = [] as PreSubmitResponse[];
    this.recordedResponses.forEach(resp => {
      const questionsForCurrentResponse: FinalQuestionResponse[] = [] as FinalQuestionResponse[];
      resp.questions.forEach(question => {
        const newQuestion: FinalQuestionResponse = {
          question_id: question.question_id,
          weightage: question.weightage,
          start_date: question.start_date,
          due_date: question.due_date,
          project_id: question.project_id,
          notify_user: question.notify_user,
          answer: question.answer,
          comments: question.comments,
          sub_questions: []
        };
        if (question.answer !== null && question.answer !== '' && question.answer.toLowerCase() === 'yes') {
          newQuestion.sub_questions = question.sub_questions;
          questionsForCurrentResponse.push(newQuestion);
        } else if (!question.answer || question.answer.toLowerCase() === 'no') {
          newQuestion.sub_questions = [];
          questionsForCurrentResponse.push(newQuestion);
        }
      });
      const qn: PreSubmitResponse = {
        emp_id: resp.emp_id,
        questions: questionsForCurrentResponse
      };
      saveOnlyQuestions.push(qn);
    });

    const request: SubmitResponse = {
      data: saveOnlyQuestions
      // questions: this.recordedResponses
    };
    // console.log(JSON.stringify(request));
    let service: any;
    if (this.dueDate) {
      service = this.managerService.postPendingManagerResponse(this.dueDate, request);
    } else {
      service = this.managerService.postManagerResponse(request);
    }
    service .subscribe(
      (data: any) => {
        if (data.msg === 'Answers are updated successfully') {
          const info = {
            msg : 'Record has been saved successfully',
            type : 'Info',
            };
          this.alertDialog(info);
        }
       },
      (error) => { }
    );
  }

  async onSubmitAns() {
    // const request = any[];
    const request: SubmitFinalResponse = {
      status: 'Completed',
      data: this.recordedResponses,
    };
    // console.log(request);
    let notAnsweredQuestions = 0;
    this.recordedResponses.forEach(resp => {
      notAnsweredQuestions += resp.questions.filter(x => !x.answer || x.answer === '').length;
      // console.log(notAnsweredQuestions);
    });
    if (notAnsweredQuestions > 0) {
      // const msg = 'You have not answered all the questions';
      const info = {
        msg : 'Looks like you have not answered all the questions.Please scroll up and ensure you have covered all the categories',
        type : 'Alert',
        };
      this.alertDialog(info);
      return;
    } else {
      const msg = 'Please verify the answers before submitting. Once submitted, You will not be able to change the answers. ';
      const dialogData = new ConfirmDialogModel('Confirm Action', msg);
      const dialogRef = this.dialog.open(ConfirmationsDialogComponent, {
        maxWidth: '400px',
        data: dialogData
      });
      this.result = await dialogRef.afterClosed().toPromise();

      if (this.result) {
        this.spinner.show();
        let service: any;
        if (this.dueDate) {
          service = this.managerService.postPendingManagerResponse(this.dueDate, request);
        } else {
          service = this.managerService.postManagerResponse(request);
        }
        service.subscribe(
          (data: any) => {
              if (data.msg === 'Answers are updated successfully') {
                this.router.navigate(['/manager/dashboard']);
              }
              this.spinner.hide();
            // this.getQuestions();
            // const updatedata = {
            //   status: 'Completed',
            //   data: this.recordedResponses,
            // };
            // this.originalQuestions.push(updatedata);
            // this.employeeData.push(updatedata);
           },
          (error) => {
            this.spinner.hide();
           }
        );
      }
    }
  }

  getChecked(employeeId: number, optionName: string, questionId: number) {
    const employeeSubRecoed = this.recordedResponses.find(x => x.emp_id === employeeId);
    const question = employeeSubRecoed.questions.find(x => x.question_id === questionId);
    if (question.answer && question.answer === optionName) {
      return true;
    }
  }

  getSubQuestionChecked(employeeId: number, questionId: number, subQuestion: SubQuestion) {
    const employeeSubRecoed = this.recordedResponses.find(x => x.emp_id === employeeId);
    const question = employeeSubRecoed.questions.find(x => x.question_id === questionId);
    const subQnRecord = question.sub_questions.find(x => x.sub_question_id === subQuestion.sub_question_id);
    return (subQnRecord && subQnRecord.answer === 'TRUE') ? true : false;
  }

  showSubQuestions(employeeId: number, questionId: number) {
    const employeeSubRecoed = this.recordedResponses.find(x => x.emp_id === employeeId);
    const question = employeeSubRecoed.questions.find(x => x.question_id === questionId);
    if (question && question.answer && question.answer.toLowerCase() === 'yes') {
      return true;
    }
  }
  showComment(employeeId: number, questionId: number) {
    const employeeSubRecoed = this.recordedResponses.find(x => x.emp_id === employeeId);
    const question = employeeSubRecoed.questions.find(x => x.question_id === questionId);
    if (question && question.answer && question.answer.toLowerCase() === 'no') {
      return true;
    }
  }

  filterCategoryWiseQuestions(filter: string) {
    this.currentCategory = filter;
    this.getQuestionsPerEmployee();
    this.scrollToTop();
  }

  getSubQuestions(employeeId: number, question) {
    const employeeRelatedData = this.employeeData.find(x => x.emp_id === employeeId);
    const questions = employeeRelatedData.categories.find(x => x.category_name === this.currentCategory).questions;
    return questions.find(x => x.question_id === question.question_id).sub_questions;
  }

  noteResponse(employeeId: number, optionName: string, questionId: number) {
    const employeeSubRecoed = this.recordedResponses.find(x => x.emp_id === employeeId);
    const question = employeeSubRecoed.questions.find(x => x.question_id === questionId);
    question.answer = optionName;
  }
  noteComment(questionId: number, employeeId: number, event: any) {
    const employeeSubRecoed = this.recordedResponses.find(x => x.emp_id === employeeId);
    const question = employeeSubRecoed.questions.find(x => x.question_id === questionId);
    question.comments = event.target.value;
  }

  noteSubQuestionResponse(questionId: number, employeeId: number, subQuestion: SubQuestion, event: any) {
    const employeeSubRecoed = this.recordedResponses.find(x => x.emp_id === employeeId);
    const question = employeeSubRecoed.questions.find(x => x.question_id === questionId);
    const subQnRecord = question.sub_questions.find(x => x.sub_question_id === subQuestion.sub_question_id);
    subQnRecord.answer = event.checked ? 'TRUE' : 'FALSE';
  }


  scrollToTop() {
    const myDiv = document.getElementById('table-scroll');
    myDiv.scrollTop = 0;
    myDiv.scrollLeft = 0;
  }
}

export interface SubmitResponse {
  data: PreSubmitResponse[];
}

export interface PreSubmitResponse {
  emp_id: number;
  questions: FinalQuestionResponse[];
}

export interface FinalQuestionResponse {
  question_id: number;
  weightage: number;
  start_date: string;
  due_date: string;
  project_id: number;
  notify_user: string;
  answer: string;
  comments: string;
  sub_questions: SubQuestion[];
}

export interface SubQuestion {
  sub_question_id: number;
  answer: string;
}

export interface SubmitFinalResponse {
  status: string;
  data: PreSubmitResponse[];
}
