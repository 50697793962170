import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearfilter'
})
export class YearfilterPipe implements PipeTransform {
  transform(year: any[]): any {
   return year.reduce((a, e) => {
      const estKey = (e['report_year']);
      (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
      return a;
    }, {});
  }

}
