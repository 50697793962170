import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-multiple-options',
  templateUrl: './multiple-options.component.html',
  styleUrls: ['./multiple-options.component.css']
})
export class MultipleOptionsComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input("orderForm") orderForm: FormGroup;
  // orderForm: FormGroup;
  items: FormArray;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.orderForm = this.formBuilder.group({
      items: this.formBuilder.array([ this.createItem() ])
    });
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      option: new FormControl(null, Validators.required),
    });
  }
  addItem() {
    this.items = this.orderForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }
  removeItem(index) {
    const array = this.orderForm.get('items') as FormArray;
    array.removeAt(index);
  }

}
