import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { FormControl, Validators } from '@angular/forms';
import { BusinessService } from 'src/app/services/business.service';
// import * as XLSX from 'xlsx';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-overall',
  templateUrl: './overall.component.html',
  styleUrls: ['./overall.component.css']
})
export class OverallComponent implements OnInit {
  show;
  options = [
    'Yes',
    'No',
  ];
  selectedOption = 'No';
  displayedColumns: string[] = ['project', 'nofg', 'nofa', 'nofr'];

  fromDate;
  endDate;
  projectIds;
  overAllReport;
  overAllProjectR;
  dueDate = new FormControl(Validators.required);
  endFCDate = new FormControl(Validators.required);
  searchCtrl = '';
  searchbuCtrl = '';
  searchSubBuCtrl = '';
  searchProjectCtrl = '';
  searchManagerCtrl = '';
  exportedCSVManagerData = [];
  eportedXLXManagerData = [];
  exportedCSVProjectData = [];
  eportedXLXProjectData = [];
  constructor(public adminService: AdminService, private spinner: NgxSpinnerService, public businessService: BusinessService) { }

  ngOnInit() {
    this.businessService.getAssignQuestions();
  }
  onchangeEvent1(e) {
    // console.log(e);
    this.fromDate = e.target.value;
  }
  onchangeEvent2(e) {
    // console.log(e);
    this.endDate = e.target.value;
  }


  remove_duplicates(arr) {
    const s = new Set(arr);
    const it = s.values();
    return Array.from(it);
  }
  getManagers() {
    // this.projectIds = [];
    const ids = [];
    this.businessService.buData.forEach(x => {
      ids.push(x.project_id);
    });
    this.projectIds = this.remove_duplicates(ids);
    // console.log(this.projectIds);
  }
  report() {
    const request = {
      // tslint:disable-next-line:max-line-length
      report_start_date: this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate()
        + ' ' + this.fromDate.getHours() + ':' + this.fromDate.getMinutes() + ':' + this.fromDate.getSeconds(),
      // tslint:disable-next-line:max-line-length
      report_end_date: this.endDate.getFullYear() + '-' + (this.endDate.getMonth() + 1) + '-' + this.endDate.getDate()
        + ' ' + this.endDate.getHours() + ':' + this.endDate.getMinutes() + ':' + this.endDate.getSeconds(),
      project_id_list: this.projectIds
    };
    // console.log(request);
    this.adminService.overalReport(request).subscribe(
      (resp: any) => {
        this.overAllReport = resp.data;
      }
    );
  }
  managerReport() {
    const request = {
      report_start_date: this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate()
        + ' ' + this.fromDate.getHours() + ':' + this.fromDate.getMinutes() + ':' + this.fromDate.getSeconds(),
      report_end_date: this.endDate.getFullYear() + '-' + (this.endDate.getMonth() + 1) + '-' + this.endDate.getDate()
        + ' ' + this.endDate.getHours() + ':' + this.endDate.getMinutes() + ':' + this.endDate.getSeconds(),
      project_id: this.businessService.buData[0].project_id,
    };
    // console.log(request);
    this.adminService.overallProjReport(request).subscribe(
      (resp: any) => {
        this.overAllProjectR = resp.data;
      }
    );
  }
  // exportToExcelManagerLevel() {
  //   this.overAllProjectR.forEach(e => {
  //     e.status.forEach(element => {
  //        this.eportedXLXManagerData.push(Object.assign({manager_name : e.manager_name}, element));
  //     });
  //   });
  //   const workBook = XLSX.utils.book_new();
  //   const workSheet = XLSX.utils.json_to_sheet(this.eportedXLXManagerData);
  //   XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
  //   XLSX.writeFile(workBook, 'generated.xlsx');
  // }
  csvGeneratorManagerLevel() {
    this.overAllProjectR.forEach(e => {
      e.status.forEach(element => {
         this.exportedCSVManagerData.push(Object.assign({manager_name : e.manager_name}, element));
      });
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.exportedCSVManagerData);
  }
  // exportToExcel() {
  //   this.overAllReport.forEach(e => {
  //     e.status_history.forEach(element => {
  //        this.eportedXLXProjectData.push(Object.assign({project_name : e.project_name}, element));
  //     });
  //   });
  //   const workBook = XLSX.utils.book_new();
  //   const workSheet = XLSX.utils.json_to_sheet(this.eportedXLXProjectData);
  //   XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
  //   XLSX.writeFile(workBook, 'generated.xlsx');
  // }
  csvGenerator() {
    this.overAllReport.forEach(e => {
      e.status_history.forEach(element => {
         this.exportedCSVProjectData.push(Object.assign({project_name : e.project_name}, element));
      });
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.exportedCSVProjectData);
  }
}
