import { Component, OnInit, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ReportData } from '../../manager.component';
import { ManagerService } from 'src/app/services/manager.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EmployeeListComponent implements OnInit, AfterViewInit {
  // @Input() reportData;
  reportData: ReportData[] = [];
  statusDATA: ReportData[] = [];
  yearFilter: ReportData[] = [];
  statuses: string[] = [] as string[];
  questionStatuses: string[] = [] as string[];
  columnsToDisplay = ['org_emp_id', 'emp_name', 'designation', 'question_answerred_status', 'current_status'];
  months = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  expandedElement: ReportData | null;
  pendingCount;
  @Output() employeeLoaded: EventEmitter<boolean> = new EventEmitter();
  constructor(public managerService: ManagerService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getReport();
    // this.yearlyStatusFilter();
    // this.getCurrentMonth();
    // this.getCount('Pending');
  }
  ngAfterViewInit() {
  }
  getCurrentMonth() {
    const d = new Date();
    const m = d.getMonth();
  }
  filter() {
    // tslint:disable-next-line:only-arrow-functions
    const allStatuses = this.reportData.map(function(item) { return item.question_answerred_status; });
    allStatuses.forEach(status => {
      const index = this.statuses.indexOf(status);
      if (index === -1) {
        this.statuses.push(status);
      }
    });

    // tslint:disable-next-line:only-arrow-functions
    const allQstnStatuses = this.reportData.map(function(item) { return item.current_status; });
    allQstnStatuses.forEach(status => {
      const index = this.questionStatuses.indexOf(status);
      if (index === -1) {
        this.questionStatuses.push(status);
      }
    });
  }
  yearlyStatusFilter() {
    // tslint:disable-next-line:only-arrow-functions
    const yearData = this.reportData.map(function(item) { return item.status_history; });
    console.log(yearData);
  }
  historyFilter(filter) {
    this.reportData = this.yearFilter.filter(x => x.status_history === filter);
  }
  getDataElement(filter: string) {
    this.reportData = this.statusDATA.filter(x => x.question_answerred_status === filter);
  }

  getQstnDataElement(filter: string) {
    this.reportData = this.statusDATA.filter(x => x.current_status === filter);
  }
  getCount(character: any) {
  this.pendingCount = this.statusDATA.filter(x => x.question_answerred_status === character).length;
  console.log(this.pendingCount);
  }
getReport() {
  this.spinner.show();
  this.managerService.employeeReport().subscribe(
    (response: any) => {
      this.reportData = response;
      this.statusDATA = response;
      this.filter();
      this.getCount('Pending');
      // this.yearlyStatusFilter();
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    }
  );
}
}

