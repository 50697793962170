export class Constants {
    static userOptions: Qresponse[] = [
        { option_id: '1', option_name: 'Yes' },
        { option_id: '2', option_name: 'No' },
    ];
}
export interface Qresponse {
    option_id: string;
    option_name: string;
}
