import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard-chips',
  templateUrl: './dashboard-chips.component.html',
  styleUrls: ['./dashboard-chips.component.css']
})
export class DashboardChipsComponent implements OnInit {
title;
  constructor(titleService: Title, router: Router, activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.title = this.getTitle(router.routerState, router.routerState.root).join('-');
        // console.log(this.title);
        titleService.setTitle(this.title);
      }
    });
  }

  ngOnInit() {

  }
  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
  underWorking() {
    const info = {
      msg : 'Currently not available, Under Development.',
      type : 'Info',
      };
    this.alertDialog(info);
  }
}
