import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-updatestatus-dialog',
  templateUrl: './updatestatus-dialog.component.html',
  styleUrls: ['./updatestatus-dialog.component.css']
})
export class UpdatestatusDialogComponent implements OnInit {

  action: string;
  empdata;
  status: Status[] = [
    {value: '0', viewValue: 'Green'},
    {value: '1', viewValue: 'Amber'},
    {value: '2', viewValue: 'Red'}
  ];
  constructor(public dialogRef: MatDialogRef<UpdatestatusDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ReportData,
              public dialog: MatDialog) {
                dialogRef.disableClose = true;
                this.empdata = { ...data};
                this.action = this.empdata.action;
               }

  ngOnInit() {
  }
  doAction() {
    this.dialogRef.close({ event: this.action, data: this.empdata  });
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

export interface ReportData {
  'emp_name': string;
  'org_emp_id': number;
  'designation': string;
  'question_answerred_status': string;
  'current_status': string;
  'comment': string;
}

export interface Status {
value: string;
viewValue: string;
}
