import { Component, OnInit} from '@angular/core';
import { BusinessService } from 'src/app/services/business.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {

  constructor(public businessService: BusinessService) { }

  ngOnInit() {
    this.businessService.getAssignQuestions();
    }
    onTabChange(event) {
      this.businessService.clearFilter();
    }
}



