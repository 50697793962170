import { Component, OnInit } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public authServices: AuthServices, public router: Router
    ) { }

  ngOnInit() {
  //   if (this.authService.currentUser)
  //   this.router.navigate([this.authService.currentUser.login_redirect])
  // else
  //   this.router.navigate(["account/login"]);
    const role = this.authServices.socialusers.role_name;
    console.log(role);
    if (role === 'admin') {
      this.router.navigate(['/admin/dashboard']);
    }
    if (role === 'manager') {
      this.router.navigate(['/manager/dashboard']);
    } else {
      this.router.navigate(['/login']);
    }
  }

}
