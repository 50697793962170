import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'angular-6-social-login';
import { Socialusers } from 'src/app/models/socialusers';
import { AuthServices } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  socialusers = new Socialusers();
  returnUrl;
  pic;
  role;
  dualRole;
  switch;
  url;
  constructor(public OAuth: AuthService, public router: Router, private activatedRoute: ActivatedRoute, public auth: AuthServices) {
    const d: string = this.router.url;
    if (d.includes('admin')) {
      this.switch = 'manager';
      this.url = '/manager/dashboard';
    }
    if (d.includes('manager')) {
      this.switch = 'admin';
      this.url = '/admin/dashboard';
    }
   }

  ngOnInit() {
    this.socialusers = JSON.parse(localStorage.getItem('userinfo'));
    this.role = JSON.parse(localStorage.getItem('socialusers')).role_name;
    this.dualRole = this.role.role_name;
    if (this.role.includes('Admin') && this.role.includes('Manager')) {
      return this.dualRole = true;
    }
    // 'hide': this.route.url === '/' || this.route.url.toLowerCase().includes('returnurl')
    // if (this.router.url.toLowerCase().includes('admin')) {
    //   console.log('admin');
    // } else {
    //   console.log('manager');
    // }

  }


  logout() {
    // this.OAuth.signOut().then(data => {
    //   this.router.navigate([`/`]);
    // });
    localStorage.clear();
    this.router.navigate([`/`]);
  }
}
