import { Component, OnInit, Input } from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import { AdminService } from 'src/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessService } from 'src/app/services/business.service';

@Component({
  selector: 'app-individual-report',
  templateUrl: './individual-report.component.html',
  styleUrls: ['./individual-report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class IndividualReportComponent implements OnInit {
fromDate;
report;
empQuestions;
date = new FormControl(moment());
managerId;
empId;
projectId;
searchCtrl = '';
searchbuCtrl = '';
searchSubBuCtrl = '';
searchProjectCtrl = '';
searchManagerCtrl = '';
  constructor(public adminService: AdminService, private spinner: NgxSpinnerService, public businessService: BusinessService) { }

  ngOnInit() {
    this.businessService.getAssignQuestions();
    const currentDate = this.date.value._d;
    // const firstDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString();
    // console.log(firstDate);
    // console.log(JSON.stringify(firstDate));
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);

    const currentDate = ctrlValue.month(normalizedMonth.month())._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);

    datepicker.close();
  }


  onchangeEvent(e) {
    this.fromDate = e.target.value;
    console.log(e);
  }
  getEmploye() {
    // console.log(this.buData[0].manager_id);
    this.spinner.show();
    const request = {
      manager_id : this.businessService.buData[0].manager_id,
      // tslint:disable-next-line:max-line-length
      // report_date: this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate()
      //  + ' ' + this.fromDate.getHours()+ ':' + this.fromDate.getMinutes()+ ':' + this.fromDate.getSeconds()
      report_date : this.fromDate,
    };
    this.adminService.individualEmp(request, this.businessService.buData[0].project_id).subscribe(
      (response) => {
        this.report = response;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  setCurrentProject(e) {
    // console.log(e);
    this.managerId = e.manager_id;
    this.empId = e.emp_id;
    this.projectId = e.project_id;
    this.getEmpQ();
  }
  getEmpQ() {
    // console.log(t);
    this.spinner.show();
    const request = {
      // manager_id : t.manager_id,
      manager_id: this.managerId,
      // report_date: this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate(),
      //  + ' ' + this.fromDate.getHours()+ ':' + this.fromDate.getMinutes()+ ':' + this.fromDate.getSeconds()
      report_date: this.fromDate,
      // emp_id: t.emp_id,
      emp_id: this.empId,
    };
    this.adminService.idvidualEmpQ(request, this.projectId).subscribe(
      (response) => {
        this.empQuestions = response;
        // console.log(this.empQuestions);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

}

