import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';


@Component({
  selector: 'app-mng-data-dialog',
  templateUrl: './mng-data-dialog.component.html',
  styleUrls: ['./mng-data-dialog.component.css']
})
export class MngDataDialogComponent implements OnInit {
  displayedQuestions: string[] = ['SL.No.', 'question', 'Category'];
  action: string;
  managerData: any;
  constructor(public dialogRef: MatDialogRef<MngDataDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ManagerData,
              public adminService: AdminService, public dialog: MatDialog) {
    // console.log(data);
    dialogRef.disableClose = true;
    this.managerData = { ...data};
    // console.log(this.managerData);
    this.action = this.managerData.action;
   }
   ngOnInit() {
  }


   doAction() {
    this.dialogRef.close({ event: this.action, data: this.managerData });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

export interface ManagerData {
  manager_name: string;
  notified_users: string;
  due_date: string;
  assigned_data: [];
}
