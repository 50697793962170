import { Component, OnInit, Inject } from '@angular/core';
import { MultiDataSet, Label, Color } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { EmployeeListComponent } from '../employee-list/employee-list.component';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  // Doughnut
  public doughnutChartLabels: Label[] = ['Green', 'Red', 'Amber'];
  public colors: any = [{
    // borderColor: 'red',
    backgroundColor: ['#7ED321', '#FE0000' , '#FFA200'] ,
    // pointBackgroundColor: 'orange',
    // pointBorderColor: 'blue,'
  }];

      public doughnutChartData: MultiDataSet = [
    [15, 5, 5],
  ];
  public doughnutChartType: ChartType = 'doughnut';
  constructor(@Inject(EmployeeListComponent) private data: EmployeeListComponent) { }

  ngOnInit() {
    const test = this.data.statusDATA;
    console.log(test);
  }

}
