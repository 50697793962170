import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
const moment = _rollupMoment || _moment;
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ReportDashboardComponent implements OnInit {
  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         stepSize: 50,
  //         beginAtZero: true,
  //       }
  //     },],
  //   }
  // };
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 50,
          beginAtZero: true,
        }
      },],
    }
  };
  options = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 200,
          beginAtZero: true,
        }
      },],
    }
  };
  // public colors: any = [{
  //   backgroundColor: '#86C7F3',
  // }];
  public colors: any = [];
  public mngclrs: any = [];
  public piecolors: any = [{
    backgroundColor: ['#FE0000', '#FFA200', '#7ED321'],
  }];
  //  options= {
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         beginAtZero:true
  //       }
  //     },],
  //     xAxes: [{
  //       ticks: {
  //         autoSkip: false,
  //         maxRotation: 90,
  //         minRotation: 0,
  //         beginAtZero:true
  //       },
  //       barPercentage:0.4,
  //       categoryPercentage:0.7,
  //       maxBarThickness:70
  //       //                barThickness:100
  //     }]
  //   }
  // };
  public barChartEMPLabels: Label[] = ['Emps EWS rolled Out', 'Emps For Whom RAG available', 'Emps Pending Ans'];
  public barChartLabels: Label[] = ['Total Mngrs', 'Mngrs EWS Rolled Out', 'Total Mngrs Ans', 'Pending to Ans'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [];
  public barChartEMPData: ChartDataSets[] = [];
  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = ['Red', 'Amber', 'Green'];
  // public pieChartData: SingleDataSet = [300, 500, 100];
  public pieChartData: SingleDataSet = [];

  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  date = new FormControl(moment());
  fromDate;
  startDate;
  endDate;
  reportData
  constructor(public adminService: AdminService, private spinner: NgxSpinnerService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    const currentDate = this.date.value._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);
    this.getReport()    
  }
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);

    const currentDate = ctrlValue.month(normalizedMonth.month())._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);

    datepicker.close();
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
  onchangeEvent1(e) {
    // console.log(e);
    this.startDate = e.target.value;
  }
  onchangeEvent2(e) {
    // console.log(e);
    this.endDate = e.target.value;
  }

  getReport() {
    this.spinner.show();
    this.barChartData = [];
    this.barChartEMPData = [];
    this.pieChartData = [];
    this.adminService.getGrphicalReport(this.fromDate).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.reportData = res.data;
        this.colors.push({
          backgroundColor: '#86C7F3'
        })
        this.mngclrs.push({
          backgroundColor: '#FFA1B5'
        })
        if(this.reportData) {
          this.barChartData.push({
            data : [
              this.reportData.overall_managers,
              this.reportData.rolledout_managers,
              this.reportData.manager_ans,
              this.reportData.manager_pending_ans
            ],
            label: 'Managers'
          })
          this.barChartEMPData.push({
            data: [
              this.reportData.rolledout_employee,
              this.reportData.rag_generated_count,
              this.reportData.pending_ans_employee,
            ],
            label: 'Employees'
          })
          this.pieChartData.push(
            this.reportData.rag_status.Red ? this.reportData.rag_status.Red : 0,
            this.reportData.rag_status.Amber ? this.reportData.rag_status.Amber : 0,
            this.reportData.rag_status.Green ? this.reportData.rag_status.Green : 0,
          )
        }
      },
      (error) => {
        console.log(error);
        this.spinner.hide()
      }
    )
  }
}
