import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessService } from 'src/app/services/business.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-bu-related',
  templateUrl: './bu-related.component.html',
  styleUrls: ['./bu-related.component.css']
})
export class BuRelatedComponent implements OnInit {
  response;
  buData: BuData[] = [];
  buOriginalData: BuData[] = [];
  queryParams;
  // tslint:disable-next-line:variable-name
  bussiness_unit_name = '';
  // tslint:disable-next-line:variable-name
  sub_bu_name = '';
  // tslint:disable-next-line:variable-name
  project_name = '';
  searchText = '';
  buFilters: string[] = [];
  subBuFilters: string[] = [];
  projectFilters: string[] = [];
  managerFilter: string[] = [];
  gradeFilter: string[] = [];
  levelFilter: string[] = [];

  statusFilter: string[] = [];

  businesFilter;
  subBusinesFilter;
  projectDataFilter;
  managerDataFilter;
  manageLevelFilter;
  manageGradeFiler;

  statusDataFilter;

  searchCtrl = '';
  searchbuCtrl = '';
  searchSubBuCtrl = '';
  searchProjectCtrl = '';
  searchManagerCtrl = '';
  searchLevelCtrl = '';
  searchGradeCtrl = '';
  // displayedColumns: string[] = ['select', 'slno', 'BU', 'SubBU', 'Project', 'Manager', 'Status', 'Action'];
  displayedColumns: string[] = ['slno', 'BU', 'SubBU', 'Project', 'Manager', 'grade', 'level', 'Status', 'Action'];
  selection = new SelectionModel(true, []);
  // @Output() messageEvent = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-rename
  @Output('selectedUsers') selectedUsers: EventEmitter<any> = new EventEmitter<any>();
  // selectedUsers;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  dissablerequired = true;
  tempBu: BuData[] = [];
  tempSubBu: BuData[] = [];
  tempProjBu: BuData[] = [];
  templevel: BuData[] = [];
  tempGrade: BuData[] = [];
  selectionModel;
  constructor(public adminService: AdminService, private spinner: NgxSpinnerService, public router: Router) {
  }
  ngOnInit() {
    this.getAssignQuestions();
    if (this.router.url.includes('bu-related')) {
      this.dissablerequired = false;
    }
  }
  hidetable() {
    return {
      hide: this.router.url.toLowerCase().includes('bu-related'),
    };
  }
  showTable() {
    return {
      hide: !this.router.url.includes('bu-related')
    };
  }
  isAllSelected() {
    if (this.buData) {
      const numSelected = this.selection.selected.length;
      const numRows = this.buData ? this.buData.length : 0;
      return numSelected === numRows;
    }
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.buData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  public getSelectedData() {
    const item = [];
    // this.selection.selected.forEach(element => {
    //   item.push(element);
    // });
    // console.log(item);
    this.buData.forEach(element => {
      item.push(element);
    });
    this.selectedUsers.emit(item);
  }


  prepareInitialFilters() {
    this.buData.forEach(element => {
      if (this.buFilters.indexOf(element.bu_name) === -1) {
        this.buFilters.push(element.bu_name);
      }
      if (this.subBuFilters.indexOf(element.sub_bu_name) === -1) {
        this.subBuFilters.push(element.sub_bu_name);
      }
      if (this.projectFilters.indexOf(element.project_name) === -1) {
        this.projectFilters.push(element.project_name);
      }
      if (this.managerFilter.indexOf(element.manager_name) === -1) {
        this.managerFilter.push(element.manager_name);
      }
      if (this.levelFilter.indexOf(element.manager_level) === -1) {
        this.levelFilter.push(element.manager_level);
      }
      if (this.gradeFilter.indexOf(element.manager_grade) === -1) {
        this.gradeFilter.push(element.manager_grade);
      }
      if (this.statusFilter.indexOf(element.status) === -1) {
        this.statusFilter.push(element.status);
      }
    });
  }

  getAssignQuestions() {
    this.spinner.show();
    this.adminService.getAssignQuestions().subscribe(
      (response: any) => {
        this.buOriginalData = response.data;
        this.buData = response.data;
        this.response = new MatTableDataSource(this.buData);
        this.response.paginator = this.paginator;
        this.response.sort = this.sort;
        // console.log(this.buData);
        this.prepareInitialFilters();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  prepareSubFilters() {
    this.subBusinesFilter = '';
    this.subBuFilters = [];
    const currentSubFilters = this.buData.map((a) => a.sub_bu_name);
    currentSubFilters.forEach(subfilter => {
      if (this.subBuFilters.indexOf(subfilter) === -1) {
        this.subBuFilters.push(subfilter);
      }
    });
  }

  prepareProjFilters() {
    this.projectDataFilter = '';
    this.projectFilters = [];
    const currentProjFilters = this.buData.map((a) => a.project_name);
    currentProjFilters.forEach(projfilter => {
      if (this.projectFilters.indexOf(projfilter) === -1) {
        this.projectFilters.push(projfilter);
      }
    });
  }
  prepareManagerFilters() {
    this.managerDataFilter = '';
    this.managerFilter = [];
    const currentManagerFilters = this.buData.map((a) => a.manager_name);
    currentManagerFilters.forEach(managerFilter => {
      if (this.managerFilter.indexOf(managerFilter) === -1) {
        this.managerFilter.push(managerFilter);
      }
    });
  }
  prepareLevelFilter() {
    this.manageLevelFilter = '';
    this.levelFilter = [];
    const currentLevelFilters = this.buData.map((a) => a.manager_level);
    currentLevelFilters.forEach(element => {
      if (this.levelFilter.indexOf(element) === -1) {
        this.levelFilter.push(element);
      }
    });
  }
  prepareGradeFilter() {
    this.manageGradeFiler = '';
    this.gradeFilter = [];
    const currentGradeFilter = this.buData.map((a) => a.manager_grade);
    currentGradeFilter.forEach(element => {
      if (this.gradeFilter.indexOf(element) === -1) {
        this.gradeFilter.push(element);
      }
    });
  }
  prepareStatusFilters() {
    this.statusDataFilter = '';
    this.statusFilter = [];
    const currentStatusFilters = this.buData.map((a) => a.status);
    currentStatusFilters.forEach(statusfilter => {
      if (this.statusFilter.indexOf(statusfilter) === -1) {
        this.statusFilter.push(statusfilter);
      }
    });
  }

  clearFilter() {
    // tslint:disable-next-line:max-line-length
    this.businesFilter = this.subBusinesFilter = this.projectDataFilter = this.managerDataFilter = this.manageGradeFiler = this.manageLevelFilter = this.statusDataFilter = '';
    this.buData = this.buOriginalData;
    this.prepareInitialFilters();
  }

  isNullOrEmpty(val) {
    return !val || val === '';
  }
  bu(event, obj) {
    // console.log(obj);
    // console.log(event);
    if (obj === 'businesUnit') {
      this.onFilterChange(0);
    } else if (obj === 'subBusinesUnit') {
      this.onFilterChange(1);
    } else if (obj === 'project') {
      this.onFilterChange(2);
    } else if (obj === 'manager') {
      this.onFilterChange(3);
    } else if (obj === 'grade') {
      this.onFilterChange(4);
    } else if (obj === 'level') {
      this.onFilterChange(5);
    } else {
      this.onFilterChange(6);
    }
  }
  onFilterChange(type: filterType) {
    switch (type) {
      case filterType.bu:
        this.tempBu = [];
        const bu = Object.values(this.businesFilter);
        // console.log(bu);
        if (bu.length > 0) {
          bu.forEach(element => {
            const businessUnit = this.buOriginalData.filter(x => x.bu_name === element);
            businessUnit.forEach(ele => {
              this.tempBu.push(ele);
            });
          });
        } else {
          this.tempBu = this.buOriginalData;
        }
        this.buData = this.tempBu;
        this.prepareSubFilters();
        this.prepareProjFilters();
        this.prepareManagerFilters();
        this.prepareLevelFilter();
        this.prepareGradeFilter();
        this.prepareStatusFilters();
        break;
      case filterType.subbu:
        this.tempSubBu = [];
        const subBu = Object.values(this.subBusinesFilter);
        if (this.tempBu.length > 0) {
          if (subBu.length > 0) {
            subBu.forEach(element => {
              const subBusiness = this.tempBu.filter(x => x.sub_bu_name === element);
              subBusiness.forEach(ele => {
                this.tempSubBu.push(ele);
              });
            });
          } else {
            this.tempSubBu = this.tempBu;
            this.subBusinesFilter = '';
            this.tempBu = [];
          }
        } else if (subBu.length > 0) {
          this.tempBu = [];
          subBu.forEach(element => {
            const subBusiness = this.buOriginalData.filter(x => x.sub_bu_name === element);
            subBusiness.forEach(ele => {
              this.tempSubBu.push(ele);
            });
          });
        } else {
          this.tempSubBu = this.buOriginalData;
          this.clearFilter();
        }
        this.buData = this.tempSubBu;
        this.prepareProjFilters();
        this.prepareManagerFilters();
        this.prepareLevelFilter();
        this.prepareGradeFilter();
        this.prepareStatusFilters();
        break;
      case filterType.project:
        this.tempProjBu = [];
        const projects = Object.values(this.projectDataFilter);
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0) {
          if (projects.length > 0) {
            projects.forEach(element => {
              const proj = this.tempSubBu.filter(x => x.project_name === element);
              proj.forEach(ele => {
                this.tempProjBu.push(ele);
              });
            });
          } else {
            this.tempProjBu = this.tempSubBu;
            this.projectDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempBu.length > 0) {
          if (projects.length > 0) {
            projects.forEach(element => {
              const proj = this.tempBu.filter(x => x.project_name === element);
              proj.forEach(ele => {
                this.tempProjBu.push(ele);
              });
            });
          } else {
            this.tempProjBu = this.tempBu;
            this.projectDataFilter = '';
            this.tempBu = [];
          }
        } else if (this.tempSubBu.length > 0) {

          if (projects.length > 0) {
            projects.forEach(element => {
              const proj = this.tempSubBu.filter(x => x.project_name === element);
              proj.forEach(ele => {
                this.tempProjBu.push(ele);
              });
            });
          } else {
            this.tempProjBu = this.tempSubBu;
            this.projectDataFilter = '';
            this.tempSubBu = [];
          }
        } else if (projects.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          projects.forEach(element => {
            const proj = this.buOriginalData.filter(x => x.project_name === element);
            proj.forEach(ele => {
              this.tempProjBu.push(ele);
            });
          });
        } else {
          this.tempProjBu = this.buOriginalData;
          // this.projectDataFilter = '';
          this.clearFilter();
        }
        // console.log(this.tempProjBu);
        this.buData = this.tempProjBu;
        this.prepareManagerFilters();
        this.prepareLevelFilter();
        this.prepareGradeFilter();
        this.prepareStatusFilters();
        break;
      case filterType.manager:
        let tempManagerBu: BuData[] = [];
        const manager = Object.values(this.managerDataFilter);
        if (manager.length > 0) {
          manager.forEach(element => {
            const mng = this.buOriginalData.filter(x => x.manager_name === element);
            mng.forEach(ele => {
              tempManagerBu.push(ele);
            });
          });
        } else {
          tempManagerBu = this.buOriginalData;
          this.clearFilter();
        }
        this.buData = tempManagerBu;
        this.prepareLevelFilter();
        this.prepareGradeFilter();
        this.prepareStatusFilters();
        break;
      case filterType.grade:
        this.tempGrade = [];
        const tempGr = Object.values(this.manageGradeFiler);
        // console.log(this.tempProjBu);
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProjBu.length > 0) {
          if (this.tempProjBu.length > 0) {
            if (tempGr.length > 0) {
              tempGr.forEach(element => {
                const grade = this.tempProjBu.filter(x => x.manager_grade === element);
                grade.forEach(ele => {
                  this.tempGrade.push(ele);
                });
              });
            } else {
              this.tempGrade = this.tempProjBu;
              this.manageGradeFiler = '';
              this.tempProjBu = [];
            }
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0) {
          if (this.tempSubBu.length > 0) {
            if (tempGr.length > 0) {
              tempGr.forEach(element => {
                const grade = this.tempSubBu.filter(x => x.manager_grade === element);
                grade.forEach(ele => {
                  this.tempGrade.push(ele);
                });
              });
            } else {
              this.tempGrade = this.tempSubBu;
              this.manageGradeFiler = '';
              this.tempSubBu = [];
            }
          }
        } else if (this.tempBu.length > 0 && this.tempProjBu.length > 0) {
          if (this.tempProjBu.length > 0) {
            if (tempGr.length > 0) {
              tempGr.forEach(element => {
                const grade = this.tempProjBu.filter(x => x.manager_grade === element);
                grade.forEach(ele => {
                  this.tempGrade.push(ele);
                });
              });
            } else {
              this.tempGrade = this.tempProjBu;
              this.manageGradeFiler = '';
              this.tempSubBu = [];
            }
          }
        } else if (this.tempBu.length > 0) {
          if (tempGr.length > 0) {
            tempGr.forEach(element => {
              const grade = this.tempBu.filter(x => x.manager_grade === element);
              grade.forEach(ele => {
                this.tempGrade.push(ele);
              });
            });
          } else {
            this.tempGrade = this.tempBu;
            this.manageGradeFiler = '';
            this.tempBu = [];
          }
        } else if (this.tempSubBu.length > 0) {
          if (tempGr.length > 0) {
            tempGr.forEach(element => {
              const grade = this.tempSubBu.filter(x => x.manager_grade === element);
              grade.forEach(ele => {
                this.tempGrade.push(ele);
              });
            });
          } else {
            this.tempGrade = this.tempSubBu;
            this.manageGradeFiler = '';
            this.tempSubBu = [];
          }
        } else if (this.tempProjBu.length > 0) {
          if (tempGr.length > 0) {
            tempGr.forEach(element => {
              const grade = this.tempProjBu.filter(x => x.manager_grade === element);
              grade.forEach(ele => {
                this.tempGrade.push(ele);
              });
            });
          } else {
            this.tempGrade = this.tempProjBu;
            this.manageGradeFiler = '';
            this.tempProjBu = [];
          }
        } else if (tempGr.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          this.tempProjBu = [];
          tempGr.forEach(element => {
            const grade = this.buOriginalData.filter(x => x.manager_grade === element);
            grade.forEach(ele => {
              this.tempGrade.push(ele);
            });
          });
        } else {
          this.tempBu = [];
          this.tempSubBu = [];
          this.tempProjBu = [];
          this.tempGrade = this.buOriginalData;
          this.clearFilter();
        }

        this.buData = this.tempGrade;
        this.prepareLevelFilter();
        this.prepareStatusFilters();
        break;
      case filterType.level:
        this.templevel = [];
        const tempL = Object.values(this.manageLevelFilter);
        if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProjBu.length > 0 && this.tempGrade.length > 0) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempGrade.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempGrade;
            this.manageLevelFilter = '';
            this.tempGrade = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempProjBu.length > 0) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempProjBu.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempProjBu;
            this.manageLevelFilter = '';
            this.tempProjBu = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length > 0 && this.tempGrade.length > 0) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempGrade.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempGrade;
            this.manageLevelFilter = '';
            this.tempGrade = [];
          }
        } else if (this.tempBu.length > 0 && this.tempSubBu.length) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempSubBu.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempSubBu;
            this.manageLevelFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempBu.length > 0 && this.tempProjBu.length) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempProjBu.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempProjBu;
            this.manageLevelFilter = '';
            this.tempProjBu = [];
          }
        } else if (this.tempSubBu.length > 0 && this.tempProjBu.length) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempProjBu.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempProjBu;
            this.manageLevelFilter = '';
            this.tempProjBu = [];
          }
        } else if (this.tempBu.length > 0 && this.tempGrade.length) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempGrade.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempGrade;
            this.manageLevelFilter = '';
            this.tempGrade = [];
          }
        } else if (this.tempSubBu.length > 0 && this.tempGrade.length) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempGrade.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempGrade;
            this.manageLevelFilter = '';
            this.tempGrade = [];
          }
        } else if (this.tempProjBu.length > 0 && this.tempGrade.length > 0) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempGrade.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempGrade;
            this.manageLevelFilter = '';
            this.tempGrade = [];
          }
        } else if (this.tempBu.length > 0) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempBu.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempBu;
            this.manageLevelFilter = '';
            this.tempBu = [];
          }
        } else if (this.tempSubBu.length > 0) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempSubBu.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempSubBu;
            this.manageLevelFilter = '';
            this.tempSubBu = [];
          }
        } else if (this.tempProjBu.length > 0) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempProjBu.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempProjBu;
            this.manageLevelFilter = '';
            this.tempProjBu = [];
          }
        } else if (this.tempGrade.length > 0) {
          if (tempL.length > 0) {
            tempL.forEach(element => {
              const level = this.tempGrade.filter(x => x.manager_level === element);
              level.forEach(ele => {
                this.templevel.push(ele);
              });
            });
          } else {
            this.templevel = this.tempGrade;
            this.manageLevelFilter = '';
            this.tempGrade = [];
          }
        } else if (tempL.length > 0) {
          this.tempBu = [];
          this.tempSubBu = [];
          this.tempProjBu = [];
          this.tempGrade = [];
          tempL.forEach(element => {
            const level = this.buOriginalData.filter(x => x.manager_level === element);
            level.forEach(ele => {
              this.templevel.push(ele);
            });
          });
        } else {
          this.templevel = this.buOriginalData;
          this.clearFilter();
        }
        this.buData = this.templevel;
        this.prepareStatusFilters();
        break;
      case filterType.status:
        let tempStatusBu: BuData[] = [];
        const tempStatus = Object.values(this.statusDataFilter);
        if (tempStatus.length > 0) {
          tempStatus.forEach(element => {
            const sts = this.buOriginalData.filter(x => x.status === element);
            sts.forEach(ele => {
              tempStatusBu.push(ele);
            });
          });
        } else {
          tempStatusBu = this.buOriginalData;
          this.clearFilter();
        }
        this.buData = tempStatusBu;
        break;
      default:
        break;
    }
    this.table.renderRows();
  }
}

export interface BuData {
  bu_name: string;
  sub_bu_name: string;
  project_name: string;
  manager_name: string;
  manager_id: string;
  project_id: number;
  manager_grade: string;
  manager_level: string;
  status: string;
}

export enum filterType {
  bu,
  subbu,
  project,
  manager,
  grade,
  level,
  status
}

