import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Socialusers } from '../models/socialusers';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  socialusers = new Socialusers();
  token;
  role;
  constructor(public router: Router) {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    this.token = this.socialusers ? this.socialusers.access_token : undefined;
    this.role = this.socialusers ? this.socialusers.role_name : undefined;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.token && this.role.includes('Admin')) {
      return true;
    } else {
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
      return false;
    }

  }
}
