import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { BusinessService } from 'src/app/services/business.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
interface ReportNode {
  name: string;
  route: string;
  icon: string;
  children?: ReportNode[];
}
const TREE_DATA: ReportNode[] = [
  {
    name: 'Reports',
    route: '',
    icon: 'report',
    children: [
      { name: 'Individual', route: '/admin/individual-report', icon: 'filter' },
      { name: 'Overall', route: '/admin/overal-report', icon: 'filter_9_plus' },
      { name: 'RAG Status', route: '/admin/l2-actions', icon: 'filter_b_and_w' },
      { name: 'Monthly', route: '/admin/report-dashboard', icon: 'assessment' },
      { name: 'Consolidated', route: '/admin/consolidated-report', icon: 'analytics' },
    ]
  },
];
interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {
  showFiller = false;
  title;
  sidenavWidth = 4;
  private _transformer = (node: ReportNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      route: node.route,
      icon: node.icon,
      level: level,
    };
  }
  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  constructor(titleService: Title, public router: Router, public activatedRoute: ActivatedRoute, public businessService: BusinessService) {
    this.dataSource.data = TREE_DATA;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.title = this.getTitle(router.routerState, router.routerState.root).join('-');
        // console.log(this.title);
        titleService.setTitle(this.title);
      }
    });
  }
  hasChild = (_: number, node: FlatNode) => node.expandable;
  ngOnInit() {

  }
  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  increase() {
    this.sidenavWidth = 18;
  }
  decrease() {
    this.sidenavWidth = 4;
  }
  // onNavigate() {
  //   this.router.navigate(['/admin/report']);
  //   this.businessService.clearFilter();
  // }
  logout() {
    localStorage.clear();
    this.router.navigate([`/`]);
  }
}
