import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.css']
})
export class TemplateDialogComponent implements OnInit {
  action: string;
  tempdata: any;
  month: Month[] = [
    {value: 1, month: 'Monthly'},
    {value: 2, month: 'Quarterly'},
    {value: 3, month: 'Yearly'},
  ];
  constructor(public dialogRef: MatDialogRef<TemplateDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) data: TemplateData ) {
                dialogRef.disableClose = true;
                this.tempdata = { ...data };
                console.log(this.tempdata);
                this.action = this.tempdata.action;
               }

  ngOnInit() {
}
doAction() {
  this.dialogRef.close({ event: this.action, data: this.tempdata });
}
closeDialog() {
  this.dialogRef.close({ event: 'Cancel' });
}
}

export interface TemplateData {
temp_id: number;
template_name: string;
template_description: string;
created_by: string;
duration_cycle: number;
}
export interface Month {
  value: number;
  month: string;
}
