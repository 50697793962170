import { Component, OnInit, ViewChild } from '@angular/core';
import { Label, MultiDataSet } from 'ng2-charts';
import { ChartType } from 'chart.js';
const moment = _rollupMoment || _moment;
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-graphical-dashboard',
  templateUrl: './graphical-dashboard.component.html',
  styleUrls: ['./graphical-dashboard.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class GraphicalDashboardComponent implements OnInit {
  public doughnutChartLabels: Label[] = ['Green', 'Red', 'Amber'];
  public colors: any = [{
    // borderColor: 'red',
    backgroundColor: ['#7ED321', '#FE0000', '#FFA200'],
    // pointBackgroundColor: 'orange',
    // pointBorderColor: 'blue,'
  }];
  public doughnutChartData: MultiDataSet = [
    [15, 5, 5],
  ];
  public doughnutChartType: ChartType = 'doughnut';
  fromDate;
  startDate;
  endDate;
  date = new FormControl(moment());
  displayedColumns: string[] = ['status', 'progress', 'duedate'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dueDate = new FormControl(Validators.required);
  endFCDate = new FormControl(Validators.required);
  constructor() { }
  ngOnInit() {
    // this.getPendingProjectList();
    this.dataSource.sort = this.sort;
    const currentDate = this.date.value._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);

    const currentDate = ctrlValue.month(normalizedMonth.month())._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);

    datepicker.close();
  }
  onchangeEvent1(e) {
    // console.log(e);
    this.startDate = e.target.value;
  }
  onchangeEvent2(e) {
    // console.log(e);
    this.endDate = e.target.value;
  }
}
export interface PeriodicElement {
  status: string;
  progress: number;
  duedate: Date;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { status: 'pending', progress: 70, duedate: new Date('Mon June 22 2020 07:44:57'), },
  { status: 'completed', progress: 30, duedate: new Date('Tue June 23 2020 07:44:57'), },
  { status: 'Overdue', progress: 10, duedate: new Date('Wed June 24 2020 07:44:57') },
  // {status: 'Overdue', progress: 10, duedate: '31 May 2020'},
];
