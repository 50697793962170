import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'EWS';
  constructor(public route: Router) {}
  hideHeader() {
    return {
      // tslint:disable-next-line:object-literal-key-quotes
      'hide': this.route.url === '/' || this.route.url.toLowerCase().includes('returnurl'),
    };
  }
}
