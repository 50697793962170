import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, MultiDataSet, Color } from 'ng2-charts';
const moment = _rollupMoment || _moment;
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { async } from '@angular/core/testing';
import { error } from 'protractor';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-consolidated-report',
  templateUrl: './consolidated-report.component.html',
  styleUrls: ['./consolidated-report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ConsolidatedReportComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 50,
          beginAtZero: true,
        }
      },],
    }
  };
  options = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 200,
          beginAtZero: true,
        }
      },],
    }
  };
  // public colors: any = [{
  //   backgroundColor: '#86C7F3',
  // }];
  public colors: Color[] = [];

  public barChartEMPLabels: Label[] = ['Emps EWS rolled Out', 'Emps For Whom RAG available', 'Emps Pending Ans'];
  public barChartLabels: Label[] = ['Total Mngrs', 'Mngrs EWS Rolled Out', 'Total Mngrs Ans', 'Pending to Ans'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [];
  public barChartEMPData: ChartDataSets[] = [];
  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  // public doughnutChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  // public doughnutChartData: MultiDataSet = [
  //   [350, 450, 100],
  //   [50, 150, 120],
  //   [250, 130, 70],
  // ];
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData: MultiDataSet = [];
  public doughnutChartType: ChartType = 'doughnut';

  public pieChartLabels: Label[] = ['Green', 'Amber', 'Red'];
  // public pieChartData: SingleDataSet = [300, 500, 100];
  public pieChartData: SingleDataSet = [];

  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  date = new FormControl(moment());
  fromDate;
  startDate;
  endDate;
  // overalReport = [ { "September": { "overall_managers": 134, "manager_ans": 44, "manager_pending_ans": 120, "rolledout_managers": 90, "pending_ans_employee": 88, "rolledout_employee": 200, "rag_status": {'Red': 50, 'Amber': 300, 'Green': 450}, "rag_generated_count": 200 } }, { "October": { "overall_managers": 134, "manager_ans": 88, "manager_pending_ans": 182, "rolledout_managers": 73, "pending_ans_employee": 120, "rolledout_employee": 230, "rag_status": {'Red': 90, 'Amber': 220, 'Green': 700}, "rag_generated_count": 500 } }, { "November": { "overall_managers": 120, "manager_ans": 120, "manager_pending_ans": 130, "rolledout_managers": 110, "pending_ans_employee": 300, "rolledout_employee": 650, "rag_status": {'Red': 100, 'Amber': 250, 'Green': 700}, "rag_generated_count": 200 } },  ]
  overalReport: OveralReport[] = []

  constructor(public adminService: AdminService, private spinner: NgxSpinnerService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    const currentDate = this.date.value._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);
    this.getConsolidated()
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);

    const currentDate = ctrlValue.month(normalizedMonth.month())._d;
    const t = JSON.stringify(currentDate);
    this.fromDate = t.slice(1, 11);

    datepicker.close();
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
  onchangeEvent1(e) {
    this.startDate = e.target.value;
  }
  onchangeEvent2(e) {
    this.endDate = e.target.value;
  }

  getConsolidated() {
    this.spinner.show();
    this.overalReport = [];
    this.colors = [];
    this.doughnutChartLabels = [];
    this.barChartData = [];
    this.barChartEMPData = [];
    this.doughnutChartData = [];
    this.spinner.show();
    const lastmonth = moment(this.fromDate).subtract(1, 'months').format('YYYY-MM-DD');
    const secondlastmonth = moment(this.fromDate).subtract(2, 'months').format('YYYY-MM-DD');
    const date = new Date(this.fromDate);
    const date2 = new Date(lastmonth);
    const date3 = new Date(secondlastmonth);
    let month = date.toLocaleString('default', { month: 'long' });
    let month2 = date2.toLocaleString('default', { month: 'long' });
    let month3 = date3.toLocaleString('default', { month: 'long' });
    const l = [];
    console.log(this.overalReport);
    this.adminService.getGrphicalReport(this.fromDate).subscribe(
      (res: any) => {
        let i = res.data;
        l.push({ [month]: i })
        if (l.length > 0) {
          this.adminService.getGrphicalReport(lastmonth).subscribe(
            (resp: any) => {
              let j = resp.data
              l.push({ [month2]: j })
              if (l.length > 1) {
                this.adminService.getGrphicalReport(secondlastmonth).subscribe(
                  (response: any) => {
                    let k = response.data
                    l.push({ [month3]: k })
                    this.overalReport = l
                    if (this.overalReport.length == 3) {
                      this.spinner.hide()
                      const a = Object.keys(this.overalReport[0])
                      const b = Object.values(this.overalReport[0])

                      const c = Object.keys(this.overalReport[1])
                      const d = Object.values(this.overalReport[1])

                      const e = Object.keys(this.overalReport[2])
                      const f = Object.values(this.overalReport[2])

                      this.barChartData.push(
                        {
                          data: [
                            b[0].overall_managers,
                            b[0].rolledout_managers,
                            b[0].manager_ans,
                            b[0].manager_pending_ans
                          ],
                          label: a[0]
                        },
                        {
                          data: [
                            d[0].overall_managers,
                            d[0].rolledout_managers,
                            d[0].manager_ans,
                            d[0].manager_pending_ans
                          ],
                          label: c[0]
                        },
                        {
                          data: [
                            f[0].overall_managers,
                            f[0].rolledout_managers,
                            f[0].manager_ans,
                            f[0].manager_pending_ans
                          ],
                          label: e[0]
                        },
                      )
                      this.barChartEMPData.push(
                        {
                          data: [
                            b[0].rolledout_employee,
                            b[0].rag_generated_count,
                            b[0].pending_ans_employee,
                          ],
                          label: a[0]
                        },
                        {
                          data: [
                            d[0].rolledout_employee,
                            d[0].rag_generated_count,
                            d[0].pending_ans_employee,
                          ],
                          label: c[0]
                        },
                        {
                          data: [
                            f[0].rolledout_employee,
                            f[0].rag_generated_count,
                            f[0].pending_ans_employee,
                          ],
                          label: e[0]
                        }
                      )
                      this.colors.push(
                        {
                          backgroundColor: ['#FE0000', '#FFA200', '#7ED321',],
                        },
                        {
                          backgroundColor: ['#FE0000', '#FFA200', '#7ED321'],
                        },
                        {
                          backgroundColor: ['#FE0000', '#FFA200', '#7ED321'],
                        }
                      )
                      this.doughnutChartLabels.push('Red', 'Amber', 'Green')
                      this.doughnutChartData.push(
                        [b[0].rag_status.Red ? b[0].rag_status.Red : 0, b[0].rag_status.Amber ? b[0].rag_status.Amber : 0, b[0].rag_status.Green ? b[0].rag_status.Green : 0],

                        [d[0].rag_status.Red ? d[0].rag_status.Red : 0, d[0].rag_status.Amber ? d[0].rag_status.Amber : 0, d[0].rag_status.Green ? d[0].rag_status.Green : 0],

                        [f[0].rag_status.Red ? f[0].rag_status.Red : 0, f[0].rag_status.Amber ? f[0].rag_status.Amber : 0, f[0].rag_status.Green ? f[0].rag_status.Green : 0]
                      )
                    }
                  },
                  (error) => {
                    this.spinner.hide()
                    console.log(error);
                    
                  }
                )
              }
            },
            (error) => {
              this.spinner.hide()
              console.log(error);
              
            }
          )
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }
}

export interface OveralReport {
  overall_managers: number;
  manager_ans: number;
  manager_pending_ans: number;
  rolledout_managers: number;
  pending_ans_employee: number;
  rolledout_employee: number;
  rag_status: {};
  rag_generated_count: number;
}