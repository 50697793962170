import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { DialogComponent } from '../../component/dialog/dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-template-stepper',
  templateUrl: './template-stepper.component.html',
  styleUrls: ['./template-stepper.component.css'],
})
export class TemplateStepperComponent implements OnInit {
  navposition = 'end';
  opened = false;
  action;
  isLinear = true;
  frequency: Frequency[] = [
    { interval: '1', frequency: 'Monthly' },
    { interval: '3', frequency: 'Quarterly' },
    { interval: '12', frequency: 'Yearly' },
  ];
  endDte: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
  selectedEndDate;
  displayedColumns: string[] = ['select', 'SL.No.', 'id', 'question_name', 'category_name', 'Weightage'];
  displayQuestionPrev: string[] = ['sl', 'question', 'category_name', 'weightage'];
  displayManagers: string[] = ['slno', 'Project', 'Manager', 'level', 'grade'];
  displayAssignedQ: string[] = ['slno', 'questions', 'category', 'severity'];
  projectData;
  // questions: any[];
  questionData: any[];
  questions;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  originalQuestions: any[];
  projectQuestions;
  reporties;
  result;
  expiryDate;
  @ViewChild('sidenav',  {static: false}) sidenav: MatSidenav;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  minDate = new Date();
  create = false;
  showModifyQ = true;
  showModifyMangers = true;
  scheduler: SendQuestions = {} as SendQuestions;
  startDate;
  endDate;
  selectedQuestions;
  grades;
  projects;
  levels;
  previewQuestions = false;
  schedulerId;
  minValue;
  maxValue;
  duedateVal;
  constructor(public dialog: MatDialog, public adminService: AdminService,
              public router: Router, private spinner: NgxSpinnerService, public activatedRoute: ActivatedRoute ) {
                this.minDate.setDate(this.minDate.getDate() + 1);
                this.startDate = this.minDate;
  }
  ngOnInit() {
    if (this.router.url.includes('template-stepper-create')) {
       this.create = true;
    }
    if (this.router.url.includes('template-stepper-edit')) {
      this.schedulerId = this.activatedRoute.snapshot.params.id;
      this.getScheduler();
      // this.showModifyQ = false;
      // this.showModifyMangers = false;
    }

  }
  selected(e) {
    console.log(e);
  }
  onchangeEvent1(e) {
    this.startDate = e.target.value;
    this.duedateVal = this.startDate.getDate();
  }
  onchangeEvent2(e) {
    this.endDate = e.target.value;
  }
close() {
  this.sidenav.close();
}
open(action) {
  this.action = action;
  this.sidenav.open();
}

  addUsers(event) {
    // console.log(event);
    this.grades = [];
    this.levels = [];
    this.projects = [];
    event.forEach(element => {
    if (this.projects.indexOf(element.project_id) === -1) {
      this.projects.push(element.project_id);
    }
    if (this.grades.indexOf(element.manager_grade) === -1) {
      this.grades.push(element.manager_grade);
    }
    if (this.levels.indexOf(element.manager_level) === -1) {
      this.levels.push(element.manager_level);
    }
   });
    if (this.create) {
      this.onSave();
    } else {
      this.updateScheduler();
    }
  }
  seletedQ(event) {
    this.selectedQuestions = event;
    // console.log(this.selectedQuestions);
  }
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }

  modifyQ() {
    this.previewQuestions = false;
  }
  getScheduler() {
    this.spinner.show();
    this.adminService.getSchedulerById(this.schedulerId).subscribe(
      (response: any) => {
        this.scheduler = response.data;
        const p = [];
        response.data.questions.forEach(ele => {
          p.push(ele.question_weightage);
        });
        this.minValue = Math.min(...p);
        this.maxValue = Math.max(...p);
        this.startDate = new Date(this.scheduler.start_date);
        this.duedateVal = this.startDate.getDate();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  onSave() {
    const request: SendQuestions = {
      name: this.scheduler.name,
      due_date: this.scheduler.due_date,
      start_date: this.startDate.getFullYear() + '-' + (this.startDate.getMonth() + 1) + '-' + this.startDate.getDate(),
      end_date: this.endDate.getFullYear() + '-' + (this.endDate.getMonth() + 1) + '-' + this.endDate.getDate(),
      interval: this.scheduler.interval,
      selected_questions: this.selectedQuestions.map((x) => ({ question_id: x.question_id, weightage: x.weightage })),
      projects: this.projects,
      levels: this.levels,
      grades: this.grades
    };
    // console.log(request);
    this.spinner.show();
    this.adminService.createScheduler(request).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.msg === 'Scheduler created successfully.') {
          this.router.navigate(['/admin/scheduler']);
        }
        if (res.msg === 'The Scheduler name is already exists.') {
          const info = {
            msg: res.msg,
            type: 'Info',
          };
          this.alertDialog(info);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  updateScheduler() {
    // tslint:disable-next-line:variable-name
    let start_date;
    // tslint:disable-next-line:variable-name
    let end_date;
    // tslint:disable-next-line:variable-name
    let selected_questions;
    if (this.startDate) {
      start_date = this.startDate.getFullYear() + '-' + (this.startDate.getMonth() + 1) + '-' + this.startDate.getDate();
    } else {
      start_date =  this.scheduler.start_date;
    }
    if (this.endDate) {
      end_date = this.endDate.getFullYear() + '-' + (this.endDate.getMonth() + 1) + '-' + this.endDate.getDate();
    } else {
      end_date = this.scheduler.end_date;
    }
    if (this.selectedQuestions) {
      selected_questions = this.selectedQuestions.map((x) => ({ question_id: x.question_id, weightage: x.weightage }));
    }
    //  else {
    //   selected_questions = this.scheduler.questions.map((x) => ({ question_id: x.question_id, weightage: x.question_weightage }));
    // }
    if (this.grades) {
      // tslint:disable-next-line:no-unused-expression
      this.grades;
    }
    //  else {
    //   this.grades = [];
    //   this.scheduler.managers.forEach(element => {
    //     this.grades.push(element.grade);
    //   });
    // }
    if (this.levels) {
      // tslint:disable-next-line:no-unused-expression
      this.levels;
    }
    //  else {
    //   this.levels = [];
    //   this.scheduler.managers.forEach(element => {
    //     this.levels.push(element.level);
    //   });
    // }
    if (this.projects) {
      // tslint:disable-next-line:no-unused-expression
      this.projects;
    }
    //  else {
    //   this.projects = [];
    //   this.scheduler.managers.forEach(element => {
    //     this.projects.push(element.project_id);
    //   });
    // }



    // if (this.grades) {
    //   const h = [];
    //   const g =  [];
    //   this.scheduler.managers.forEach(element => {
    //     if (g.indexOf(element.grade) === -1) {
    //       g.push(element.grade);
    //     }
    //   });
    //   h.concat(g).forEach(element => {
    //     if (this.grades.indexOf(element) === -1) {
    //       this.grades.push(element);
    //     }
    //   });
    // } else {
    //   this.scheduler.managers.forEach(element => {
    //     if (this.grades.indexOf(element.grade) === -1) {
    //       this.grades.push(element.grade);
    //     }
    //   });
    // }
    // if (this.levels) {
    //   const m = this.levels;
    //   const l = [];
    //   this.scheduler.managers.forEach(element => {
    //     if (l.indexOf(element.level) === -1) {
    //       l.push(element.level);
    //     }
    //   });
    //   m.concat(l).forEach(element => {
    //     if (this.levels.indexOf(element) === -1) {
    //       this.levels.push(element);
    //     }
    //   });
    // } else {
    //    this.scheduler.managers.forEach(element => {
    //     if (this.levels.indexOf(element.level) === -1) {
    //       this.levels.push(element.level);
    //     }
    //   });
    // }
    // if (this.projects) {
    //   const q = this.projects;
    //   const p = [];
    //   this.scheduler.managers.forEach(element => {
    //     if (p.indexOf(element.project_id) === -1) {
    //       p.push(element.project_id);
    //     }
    //   });
    //   q.concat(p).forEach(element => {
    //     if (this.projects.indexOf(element) === -1) {
    //       this.projects.push(element);
    //     }
    //   });
    // } else {
    //   this.scheduler.managers.forEach(element => {
    //     if (this.projects.indexOf(element.project_id) === -1) {
    //       this.projects.push(element.project_id);
    //     }
    //   });
    // }
    const request: SendQuestions = {
      scheduler_id: this.schedulerId,
      name: this.scheduler.name,
      due_date: this.scheduler.due_date,
      start_date,
      end_date,
      interval: this.scheduler.interval,
      selected_questions,
      levels: this.levels,
      projects: this.projects,
      grades: this.grades
    };
    // console.log(request);
    this.spinner.show();
    this.adminService.updateScheduler(request).subscribe(
      (res: any) => {
        if (res.msg === 'Scheduler updated successfully.') {
          const info = {
            msg: res.msg,
            type: 'Info',
          };
          this.alertDialog(info);
          this.close();
          this.ngOnInit();
        }
        if (res.msg === 'The Scheduler name is already exists.') {
          const info = {
            msg: res.msg,
            type: 'Info',
          };
          this.alertDialog(info);
        }
        this.adminService.previewQuestions = false;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
}
export interface Frequency {
  interval: string;
  frequency: string;
}
export interface TemplateData {
  temp_id: number;
  template_name: string;
  created_by: string;
  duration_cycle: number;
}
export interface SendQuestions {
  scheduler_id?: number;
  name: string;
  start_date: any;
  end_date: any;
  interval: string;
  selected_questions: any[];
  projects: any[];
  levels: any[];
  grades: any[];
  due_date: string;
  questions?: any[];
  managers?: any[];
  assigned_questions?: any;
}
