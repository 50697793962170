import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ManagerComponent } from './manager/manager.component';
import { AuthComponent } from './auth/auth.component';
import { QuestionsComponent } from './manager/questions/questions.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AdminQuestionsComponent } from './admin/questions/questions.component';
import { BuRelatedComponent } from './admin/bu-related/bu-related.component';
import { QuestionsAssignComponent } from './admin/questions-assign/questions-assign.component';
import { ReportComponent } from './admin/report/report.component';
import { HomeComponent } from './shared/home/home.component';
import { TestComponentComponent } from './test-component/test-component.component';
import { AssignedQuestionsComponent } from './admin/assigned-questions/assigned-questions.component';
import { AuthGuardGuard } from './guards/auth-guard.guard';
import { AdminGuard } from './guards/admin.guard';
import { ManagerGuard } from './guards/manager.guard';
import { EmpstatusComponent } from './manager/empstatus/empstatus.component';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { EmpReportComponent } from './manager/emp-report/emp-report.component';
import { ManagerLayoutComponent } from './shared/layouts/manager-layout/manager-layout.component';
import { TemplatesComponent } from './admin/templates/templates.component';
import { QuestionAddComponent } from './admin/templates/question-add/question-add.component';
import { TemplateStepperComponent } from './admin/templates/template-stepper/template-stepper.component';
import { GraphicalDashboardComponent } from './admin/graphical-dashboard/graphical-dashboard.component';
import { IndividualReportComponent } from './admin/report/individual-report/individual-report.component';
import { OverallComponent } from './admin/report/overall/overall.component';
import { L2ActionsReportComponent } from './admin/report/l2-actions-report/l2-actions-report.component';
import { QuestionAllocationComponent } from './admin/question-allocation/question-allocation.component';
import { ReportDashboardComponent } from './admin/report/report-dashboard/report-dashboard.component';
import { ConsolidatedReportComponent } from './admin/report/consolidated-report/consolidated-report.component';


const routes: Routes = [
  // {
  //   path: '',
  //   component: HomeComponent
  // },
  {
    path: '',
    component: AuthComponent
  },
  {
    path: '',
    component: AdminLayoutComponent, children: [
      {
        path: 'admin',
        children: [
          {
            path: 'dashboard',
            component: AdminComponent,
            canActivate: [AdminGuard],
            data: {title: 'Dashboard Menu'},
          },
          {
            path: 'questions-bank',
            component: AdminQuestionsComponent,
            canActivate: [AdminGuard],
            data: {title: 'Questions Bank'}
          },
          {
            path: 'bu-related',
            component: BuRelatedComponent,
            canActivate: [AdminGuard],
            data: {title: 'Assign Questions'}
          },
          {
            path: 'questions-assign/:id/:mngId',
            component: QuestionsAssignComponent,
            canActivate: [AdminGuard],
            data: {title: 'Assign Questions'}
          },
          {
            path: 'assigned-questions/:id/:mngId',
            component: AssignedQuestionsComponent,
            canActivate: [AdminGuard],
            data: {title: 'Assigned Questions'}
          },
          {
            path: 'report',
            component: ReportComponent,
            canActivate: [AdminGuard],
            data: {title: 'Report'}
          },
          {
            path: 'scheduler',
            component: TemplatesComponent,
            canActivate: [AdminGuard],
            data: {title: 'Scheduler'}
          },
          {
            path: 'add-question',
            component: QuestionAddComponent,
            canActivate: [AdminGuard],
            data: {title: 'Add Question'}
          },
          {
            path: 'individual-report',
            component: IndividualReportComponent,
            canActivate: [AdminGuard],
            data: {title: 'Individual Report'}
          },
          {
            path: 'overal-report',
            component: OverallComponent,
            canActivate: [AdminGuard],
            data: {title: 'Overall Report'}
          },
          {
            path: 'l2-actions',
            component: L2ActionsReportComponent,
            canActivate: [AdminGuard],
            data: {title: 'L2-Actions'}
           },
           {
            path: 'graphical-dashboard',
            component: GraphicalDashboardComponent,
            canActivate: [AdminGuard],
            data: {title: 'Graphical-Dashboard'}
          },
          {
            path: 'report-dashboard',
            component: ReportDashboardComponent,
            canActivate: [AdminGuard],
            data: {title: 'EWS-Dashboard'}
          },
          {
            path: 'consolidated-report',
            component: ConsolidatedReportComponent,
            canActivate: [AdminGuard],
            data: {title: 'Consolidated-Report'}
          },
          {
            path: 'template-stepper-create',
            component: TemplateStepperComponent,
            canActivate: [AdminGuard],
            data: {title: 'template-stepper'}
          },
          {
            path: 'template-stepper-edit/:id',
            component: TemplateStepperComponent,
            canActivate: [AdminGuard],
            data: {title: 'template-stepper'}
          },
          {
            path: 'question-allocation',
            component: QuestionAllocationComponent,
            canActivate: [AdminGuard],
            data: {title: 'Question-Allocation'}
          }
        ]
      },
      {
        path: 'test',
        component: TestComponentComponent
      }
    ]
  },
  {
    path: '',
    component: ManagerLayoutComponent,
    children : [
      {
        path: 'manager',
        children: [
          {
            path: 'dashboard',
            component: ManagerComponent,
            canActivate: [ManagerGuard]
          },
          {
            path: 'questions',
            component: QuestionsComponent,
            canActivate: [ManagerGuard]
          },
          {
            path: 'questions/:date',
            component: QuestionsComponent,
            canActivate: [ManagerGuard]
          },
          {
            path: 'emp-status',
            component: EmpstatusComponent,
            canActivate: [ManagerGuard]
          },
          {
            path: 'emp-report',
            component: EmpReportComponent,
            canActivate: [ManagerGuard]
          }
        ],
      },
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
