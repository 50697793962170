import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// chart module imports
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { ManagerComponent } from './manager/manager.component';
import { AuthComponent } from './auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'src/material-module';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { QuestionsComponent } from './manager/questions/questions.component';
import { DashboardChipsComponent } from './admin/component/dashboard-chips/dashboard-chips.component';
import { BuRelatedComponent } from './admin/bu-related/bu-related.component';
import { QuestionsAssignComponent } from './admin/questions-assign/questions-assign.component';
import { QuestionDialogComponent } from './admin/component/question-dialog/question-dialog.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { GoogleLoginProvider, FacebookLoginProvider, AuthService, SocialLoginModule, AuthServiceConfig  } from 'angular-6-social-login';
import { AuthServices } from './services/auth.service';
import { AdminQuestionsComponent } from './admin/questions/questions.component';
import { EmployeeListComponent } from './manager/component/employee-list/employee-list.component';
import { OverviewComponent } from './manager/component/overview/overview.component';
import { ReportComponent } from './admin/report/report.component';
import { HomeComponent } from './shared/home/home.component';
import { TokenInterceptor } from './services/token-interceptor.service';
import { DialogComponent } from './admin/component/dialog/dialog.component';
import { TestComponentComponent } from './test-component/test-component.component';
import { MultipleOptionsComponent } from './admin/component/multiple-options/multiple-options.component';
import { ConfirmationsDialogComponent } from './admin/component/confirmations-dialog/confirmations-dialog.component';
import { AssignedQuestionsComponent } from './admin/assigned-questions/assigned-questions.component';

import { environment } from '../environments/environment';
import { EmpstatusComponent } from './manager/empstatus/empstatus.component';
import { UpdatestatusDialogComponent } from './manager/component/updatestatus-dialog/updatestatus-dialog.component';
import { IndividualReportComponent } from './admin/report/individual-report/individual-report.component';
import { OverallComponent } from './admin/report/overall/overall.component';
import { MngDataDialogComponent } from './admin/component/mng-data-dialog/mng-data-dialog.component';
import { CheckedSubqPipe } from './pipes/checked-subq.pipe';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { ManagerLayoutComponent } from './shared/layouts/manager-layout/manager-layout.component';
import { YearfilterPipe } from './pipes/yearfilter.pipe';
import { EmpReportComponent } from './manager/emp-report/emp-report.component';
import { StringFilterByPipe } from './pipes/string-filter-by.pipe';
import { TemplatesComponent } from './admin/templates/templates.component';
import { TemplateDialogComponent } from './admin/component/template-dialog/template-dialog.component';
import { QuestionAddComponent } from './admin/templates/question-add/question-add.component';
import { AssideQViewComponent } from './admin/templates/asside-q-view/asside-q-view.component';
import { TemplateStepperComponent } from './admin/templates/template-stepper/template-stepper.component';
import { GraphicalDashboardComponent } from './admin/graphical-dashboard/graphical-dashboard.component';
import { L2ActionsReportComponent } from './admin/report/l2-actions-report/l2-actions-report.component';
import { QuestionAllocationComponent } from './admin/question-allocation/question-allocation.component';
import { SelectCheckAllComponent } from './select-check-all/select-check-all.component';
import { ReportDashboardComponent } from './admin/report/report-dashboard/report-dashboard.component';
import { ConsolidatedReportComponent } from './admin/report/consolidated-report/consolidated-report.component';

export function socialConfigs() {
  const config = new AuthServiceConfig(
    [
      // {
      //   id: FacebookLoginProvider.PROVIDER_ID,
      //   provider: new FacebookLoginProvider('app -id')
      // },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.googleLoginClientId)
      }
    ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ManagerComponent,
    AuthComponent,
    HeaderComponent,
    FooterComponent,
    QuestionsComponent,
    DashboardChipsComponent,
    BuRelatedComponent,
    QuestionsAssignComponent,
    QuestionDialogComponent,
    PageNotFoundComponent,
    AdminQuestionsComponent,
    EmployeeListComponent,
    OverviewComponent,
    ReportComponent,
    HomeComponent,
    DialogComponent,
    TestComponentComponent,
    MultipleOptionsComponent,
    ConfirmationsDialogComponent,
    AssignedQuestionsComponent,
    EmpstatusComponent,
    UpdatestatusDialogComponent,
    IndividualReportComponent,
    OverallComponent,
    MngDataDialogComponent,
    CheckedSubqPipe,
    AdminLayoutComponent,
    ManagerLayoutComponent,
    YearfilterPipe,
    EmpReportComponent,
    StringFilterByPipe,
    TemplatesComponent,
    TemplateDialogComponent,
    QuestionAddComponent,
    AssideQViewComponent,
    TemplateStepperComponent,
    GraphicalDashboardComponent,
    L2ActionsReportComponent,
    QuestionAllocationComponent,
    SelectCheckAllComponent,
    ReportDashboardComponent,
    ConsolidatedReportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ChartsModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    NgxSpinnerModule,
    NgxMatSelectSearchModule,
  ],

  entryComponents: [QuestionDialogComponent, DialogComponent, ConfirmationsDialogComponent,
     UpdatestatusDialogComponent, MngDataDialogComponent, TemplateDialogComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthService,
    {
      provide: AuthServiceConfig,
      useFactory: socialConfigs
    },
    AuthServices
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
